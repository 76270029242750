import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
    makeStyles,
    Box,
    Container,
    Grid,
    Paper,
    Button,
    Divider,
    Typography,
    Chip,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import { endPoints } from "../utils/scribeEndpoints.js";

import moment from "moment-timezone";

import { api } from "../api/api";

import { where, isMyBnRDateString } from "../utils/helperFunctions";

import DoneIcon from "@material-ui/icons/Done";
import FaceIcon from "@material-ui/icons/Face";
import SyncIcon from "@material-ui/icons/Sync";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: "32px",
        fontWeight: 700,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        textTransform: "none",
    },
    subTitle: {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        textTransform: "none",
    },
    dateTitle: {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        textTransform: "none",
    },
    sectionTitle: {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        textTransform: "none",
        marginTop: 32,
    },
    fieldName: {
        fontWeight: 700,
        margin: "10px 0",
    },
    fullList: {
        width: "auto",
    },
    list: {
        width: "100%",
    },
    link: {
        textDecoration: "none",
    },
    drawer: {
        textDecoration: "none",
        color: "black",
    },
    image: {
        maxWidth: "90%",
        height: "auto",
        margin: "auto",
    },
    mobileDivider: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
}));

const shiftTimezone = (dateString, newTimezone) => {
    try {
        if (!newTimezone) {
            throw new Error("No new timezone value provided.");
        }
        if (!dateString) {
            throw new Error("No date string provided.");
        }
        if (dateString.length !== 25) {
            throw new Error(
                "Date string format is incorrect and cannot be shifted. Ensure string follows YYYY-MM-DD[T]hh:mm:ss[+/-]XX:XX format"
            );
        }
        if (newTimezone.length !== 6) {
            throw new Error(
                "New timezone format is incorrect. Ensure string follows [+/-]XX:XX format."
            );
        }
        return dateString.slice(0, -6) + newTimezone;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const TifViewer = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const scribeEndpoints = endPoints();

    //const { contactid, guestid, departureid } = props.match.params;
    const { contactid, guestid, departureid } = props.details;

    console.log(props);

    const [isP15ContactImporting, setIsP15ContactImporting] = useState(false);
    const [isP15GuestImporting, setIsP15GuestImporting] = useState(false);
    const [isP15GearImporting, setIsP15GearImporting] = useState(false);
    const [contactDetails, setContactDetails] = useState(null);
    const [guestDetails, setGuestDetails] = useState(null);
    const [departureDetails, setDepartureDetails] = useState(null);
    const [upcomingTripDetails, setUpcomingTripDetails] = useState(null);
    const [tifData, setTifData] = useState(null);
    const [gearSyncData, setGearSyncData] = useState(null);
    const [annotationsData, setAnnotationsData] = useState(null);
    const [isP15ContactSyncing, setIsP15ContactSyncing] = useState(null);
    const [isP15GuestSyncing, setIsP15GuestSyncing] = useState(null);
    const [isP15GearSyncing, setIsP15GearSyncing] = useState(null);
    const [error, setError] = useState(null);

    const handleSyncClick = () => {
        setIsP15ContactImporting(true);
        setIsP15GuestImporting(true);
        setIsP15GearImporting(true);

        if (tifData && contactDetails && guestDetails) {
            let reduceBooleanArrayToBoolean = (arr) =>
                arr.every((v) => v === true);

            let buildContactFields = () => {
                let contactFields = {};
                Object.keys(tifData.contact).forEach((key) => {
                    if (
                        tifData.contact[key].value == null ||
                        tifData.contact[key].value == ""
                    ) {
                        // console.log(key, "is null or Empty");
                    } else {
                        contactFields[key] = tifData.contact[key].value;
                    }
                });
                return contactFields;
            };

            let buildGuestFields = () => {
                let guestFields = {};
                Object.keys(tifData.guest).forEach((key) => {
                    if (
                        tifData.guest[key].value == null ||
                        tifData.guest[key].value == ""
                    ) {
                        // console.log(key, "is null or Empty");
                    } else {
                        guestFields[key] = tifData.guest[key].value;
                    }
                });
                return guestFields;
            };

            Promise.all([
                //Set Contact Information
                api(
                    `https://endpoint.scribesoft.com/v1/orgs/3487/requests/${scribeEndpoints["setContactInfo"]}?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: {
                            contactid,
                            p15_guestsid: guestid,
                            ...buildContactFields(),
                            ...buildGuestFields(),
                        },
                    }
                ),
                //Set Traveller Profile
                api(
                    `https://endpoint.scribesoft.com/v1/orgs/3487/requests/${scribeEndpoints["setTravelerProfile"]}?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: {
                            contactid,
                            p15_guestsid: guestid,
                            ...buildContactFields(),
                            ...buildGuestFields(),
                            birthdate: shiftTimezone(
                                tifData?.contact?.birthdate?.value,
                                "-08:00"
                            ),
                            p15_passportissued: shiftTimezone(
                                tifData?.contact?.p15_passportissued?.value,
                                "-08:00"
                            ),
                            p15_passportexpires: shiftTimezone(
                                tifData?.contact?.p15_passportexpires?.value,
                                "-08:00"
                            ),
                            br_visavaliditydate: shiftTimezone(
                                tifData?.guest?.br_visavaliditydate?.value,
                                "-08:00"
                            ),
                            br_visadateofexpiry: shiftTimezone(
                                tifData?.guest?.br_visadateofexpiry?.value,
                                "-08:00"
                            ),
                        },
                    }
                ),
                //Set Arrival Departure Profile
                api(
                    `https://endpoint.scribesoft.com/v1/orgs/3487/requests/${scribeEndpoints["setArrivalDeparture"]}?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: {
                            contactid,
                            p15_guestsid: guestid,
                            ...buildContactFields(),
                            ...buildGuestFields(),
                            br_flightdateofarrival: shiftTimezone(
                                tifData?.guest?.br_flightdateofarrival?.value,
                                "-08:00"
                            ),
                            br_flightdateofdeparture: shiftTimezone(
                                tifData?.guest?.br_flightdateofdeparture?.value,
                                "-08:00"
                            ),
                            br_trainarrivaldate: shiftTimezone(
                                tifData?.guest?.br_trainarrivaldate?.value,
                                "-08:00"
                            ),
                            br_traindeparturedate: shiftTimezone(
                                tifData?.guest?.br_traindeparturedate?.value,
                                "-08:00"
                            ),
                        },
                    }
                ),
                //Set Payment and Insurance
                api(
                    `https://endpoint.scribesoft.com/v1/orgs/3487/requests/${scribeEndpoints["setPaymentInsurance"]}?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: {
                            contactid,
                            p15_guestsid: guestid,
                            ...buildContactFields(),
                            ...buildGuestFields(),
                        },
                    }
                ),
                //Set Other Information and Special Requests
                api(
                    `https://endpoint.scribesoft.com/v1/orgs/3487/requests/${scribeEndpoints["setCommentsQuestions"]}?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: {
                            contactid,
                            p15_guestsid: guestid,
                            ...buildContactFields(),
                            ...buildGuestFields(),
                        },
                    }
                ),
            ])
                .then((res) => {
                    let successArray = [
                        res[0].setContactInfo[0].success,
                        res[1].setTravelerProfile[0].success,
                        res[2].setArrivalDeparture[0].success,
                        res[3].setPaymentInsurance[0].success,
                        res[4].setCommentsQuestions[0].success,
                    ];
                    if (reduceBooleanArrayToBoolean(successArray)) {
                        let requestHeaders = {
                            "Content-Type": "application/json",
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        };

                        Promise.all([
                            api(
                                `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/enableP15Syncing/contact/${contactid}`,
                                {
                                    headers: requestHeaders,
                                }
                            ),
                            api(
                                `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/enableP15Syncing/guest/${guestid}`,
                                {
                                    headers: requestHeaders,
                                }
                            ),
                        ]).then((data) => {
                            if (data[0].success && data[1].success) {
                                setIsP15ContactSyncing(true);
                                setIsP15GuestSyncing(true);
                                setIsP15ContactImporting(false);
                                setIsP15GuestImporting(false);
                            } else {
                                setError(
                                    "Failed to update contact and guest record syncing status in intermediary database, contect Jeff for support."
                                );
                                throw new Error("DB Update Failed");
                            }
                        });
                    } else {
                        setError(
                            "Failed to update contact and guest record in Peak15, please reload TIF viewer and try again. If this error continues contect Jeff for support."
                        );
                        throw new Error("Peak15 Update Failed");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setError(
                        "Failed to update contact and guest record in Peak15, please reload TIF viewer and try again. If this error continues contect Jeff for support."
                    );
                });

            //Update Gear Selection
            Promise.all(
                gearSyncData.to_delete.map((recordToDelete) =>
                    api(
                        `https://endpoint.scribesoft.com/v1/orgs/3487/requests/${scribeEndpoints["deleteTripGear"]}?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: {
                                relationship_id: recordToDelete.relationship_id,
                            },
                        }
                    )
                )
            )
                .then((deleteResults) => {
                    let deleteSuccessArray = deleteResults.map((result) =>
                        result.data ? result.data[0].success : false
                    );

                    if (reduceBooleanArrayToBoolean(deleteSuccessArray)) {
                        Promise.all(
                            gearSyncData.to_insert.map((recordToInsert) =>
                                api(
                                    `https://endpoint.scribesoft.com/v1/orgs/3487/requests/${scribeEndpoints["insertTripGear"]}?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135`,
                                    {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: {
                                            cir_id: recordToInsert.cir_id,
                                            guest_id: recordToInsert.guest_id,
                                        },
                                    }
                                )
                            )
                        )
                            .then((insertResults) => {
                                let insertSuccessArray = insertResults.map(
                                    (result) =>
                                        result.data
                                            ? result.data[0].success
                                            : false
                                );

                                if (
                                    reduceBooleanArrayToBoolean(
                                        insertSuccessArray
                                    )
                                ) {
                                    let requestHeaders = {
                                        "Content-Type": "application/json",
                                        Authorization:
                                            "Bearer " +
                                            localStorage.getItem("token"),
                                    };

                                    Promise.all(
                                        gearSyncData.to_insert.map(
                                            (recordToInsert) =>
                                                api(
                                                    `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/enableP15Syncing/gearOption/${recordToInsert.relationship_id}`,
                                                    {
                                                        headers: requestHeaders,
                                                    }
                                                )
                                        )
                                    )
                                        .then((data) => {
                                            let UpdateGeaIsP15SyncingSuccessArray =
                                                data.map(
                                                    (result) => result.success
                                                );
                                            if (
                                                reduceBooleanArrayToBoolean(
                                                    UpdateGeaIsP15SyncingSuccessArray
                                                )
                                            ) {
                                                setIsP15GearSyncing(true);
                                                setIsP15GearImporting(false);
                                            } else {
                                                setError(
                                                    "Failed to update gear record syncing status in intermediary database, contect Jeff for support."
                                                );
                                                throw new Error(
                                                    "DB is_p15_syncing Update Failed"
                                                );
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                            setError(
                                                "Failed to insert gear into in Peak15, please reload TIF viewer and try again. If this error continues contect Jeff for support."
                                            );
                                        });
                                } else {
                                    setError(
                                        "Failed to insert gear record into Peak15, manual check required."
                                    );
                                    throw new Error(
                                        "A Peak15 Gear Insert Failed, Please Check Manually"
                                    );
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                setError(
                                    "Failed to insert gear in Peak15, please reload TIF viewer and try again. If this error continues contect Jeff for support."
                                );
                            });
                    } else {
                        setError(
                            "Failed to delete gear records in Peak15, manual check required."
                        );
                        throw new Error(
                            "A Peak15 Gear Delete Failed, Please Check Manually"
                        );
                    }
                })
                .catch((err) => {
                    setError(
                        "Failed to delete gear records in Peak15, manual check required."
                    );
                    console.log(err);
                });
        }

        if (
            gearSyncData.to_delete.length === 0 &&
            gearSyncData.to_insert.length === 0 &&
            !upcomingTripDetails?.getUpcomingTrip[0].is_p15_syncing.gear
        ) {
            fetch(
                `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/enableP15Syncing/gearOptions/${guestid}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                });
        }
    };

    let activeEquipmentFields = () => {
        if (!tifData.equipment) return [];
        let activeEquipmentFieldsArr = [];
        Object.keys(tifData.equipment).forEach((key) => {
            if (tifData.equipment[key] != null) {
                activeEquipmentFieldsArr.push(key);
            }
        });
        return activeEquipmentFieldsArr;
    };

    let adminNameFields = ["br_agreedtoprivacypolicydate"];

    let contactNameFields = [
        "p15_title",
        "firstname",
        "middlename",
        "lastname",
        "p15_preferredname",
    ];

    let contactPhoneFields = ["mobilephone", "telephone2", "telephone1"];

    let contactHomeAddressFields = [
        "address1_line1",
        "address1_line2",
        "address1_city",
        "address1_stateorprovince",
        "address1_postalcode",
        "address1_country",
        "address1_name",
    ];

    let contactShippingAddressFields = [
        // "sameAsHomeAddress",
        "address2_line1",
        "address2_line2",
        "address2_city",
        "address2_stateorprovince",
        "address2_postalcode",
        "address2_country",
        "address2_name",
    ];

    let contactEmergencyContact1Fields = [
        "p15_emergency1name",
        "p15_emergency1relationship",
        "p15_emergency1email",
        "p15_emergency1mobilephone",
        "p15_emergency1homephone",
        "p15_emergency1workphone",
    ];

    let contactEmergencyContact2Fields = [
        //  "addSecondEContact",
        "p15_emergency2name",
        "p15_emergency2relationship",
        "p15_emergency2email",
        "p15_emergency2mobilephone",
        "p15_emergency2homephone",
        "p15_emergency2workphone",
    ];

    let guestWaiverFields = ["br_datewaiverreceived"];

    let contactPersonalDetailsFields = [
        // "br_height_ft",
        // "br_height_in",
        "br_height_float",
        "br_weight",
        "br_jerseysize",
        "br_tshirtsize",
        "br_flippersize",
        "birthdate",
    ];

    let contactHealthDetailsFields = [
        "br_allergies",
        "br_dietarypreferences",
        "br_medicalconditions",
    ];

    let contactPassportDetailsFields = [
        "p15_nameonpassport",
        "p15_passportnumber",
        "p15_placeofissue",
        "p15_passportissued",
        "p15_passportexpires",
        "p15_placeofbirth",
        "p15_passportnationality",
    ];

    let guestTravelVisaDetailsFields = [
        "br_visanumber",
        "br_visavaliditydate",
        "br_visadateofexpiry",
    ];

    let guestPrePostDetailsFields = [
        "br_hotelnamenightpriortotrip",
        "br_posttriptravelplans",
    ];

    let guestArrivalFlightDetailsFields = [
        "br_flightarrivalcity",
        "br_flightarrivalflightnumber",
        "br_flightarrivaloncarrier",
        "br_flightarrivaltime",
        "br_flightdateofarrival",
    ];

    let guestDepartureFlightDetailsFields = [
        "br_flightdeparturecity",
        "br_flightdepartureflightnumber",
        "br_flightdepartureoncarrier",
        "br_flightdeparturetime",
        "br_flightdateofdeparture",
    ];

    let guestArrivalTrainDetailsFields = [
        "br_arrivaltraincarrier",
        "br_arrivaltrainnumber",
        "br_arrivaltrainstation",
        "br_trainarrivaldate",
        "br_trainarrivaltime",
    ];

    let guestDepartureTrainDetailsFields = [
        "br_departuretraincarrier",
        "br_departuretrainnumber",
        "br_departuretrainstation",
        "br_traindeparturedate",
        "br_traindeparturetime",
    ];

    let guestFinalPaymentDetailsFields = ["br_finalpaymentmethod"];

    let guestInsuranceDetailsFields = [
        "br_insurancestatus",
        "br_insurancecomments",
    ];

    let guestCommentDetailsFields = ["br_travellercomments"];

    function handleBackClick() {
        history.push(`/tifOverview`);
    }

    const OneColumnLayout = ({ title, sectionFields, responseObject }) => {
        let fields = [];
        if (responseObject) {
            sectionFields.map((field) => {
                fields.push({
                    label: responseObject[field].label,
                    display_value: responseObject[field].display_value,
                    has_changed: responseObject[field].has_changed,
                });
            });
        }

        const handleOneColumnExceptions = (title) => {
            switch (title) {
                case "Final Payment":
                    return (
                        <>
                            <Container
                                style={{
                                    marginTop: "24px",
                                    padding: "0px",
                                    display: "flex",
                                    backgroundColor: tifData.guest
                                        .br_datewaiverreceived.has_changed
                                        ? "rgb(253, 237, 237)"
                                        : "",
                                }}
                            >
                                {tifData.guest.br_datewaiverreceived
                                    .has_changed ? (
                                    <ErrorOutlineIcon
                                        style={{
                                            marginTop: "8px",
                                            marginRight: "5px",
                                            color: "rgb(239, 83, 80)",
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                                <FormControlLabel
                                    disabled
                                    control={
                                        <Checkbox
                                            checked={
                                                tifData.guest
                                                    .br_authorizedfinalpayment
                                                    .display_value === true
                                            }
                                            name="authorizedFinalPayment"
                                        />
                                    }
                                    label="Traveller has authorized final payment."
                                />
                            </Container>
                            <Grid container>
                                {fields.map((field, i, arr) => {
                                    return (
                                        <>
                                            <Grid item xs={6} sm={3}>
                                                <p
                                                    className={
                                                        classes.fieldName
                                                    }
                                                >
                                                    {field.label}:
                                                </p>
                                            </Grid>
                                            <Grid item xs={6} sm={9}>
                                                <p style={{ margin: "10px 0" }}>
                                                    {field.display_value}
                                                </p>
                                            </Grid>

                                            {i == arr.length - 1 ? (
                                                <></>
                                            ) : (
                                                <Divider
                                                    style={{ width: "100%" }}
                                                />
                                            )}
                                        </>
                                    );
                                })}
                            </Grid>
                        </>
                    );
                default:
                    break;
            }
            return (
                <Grid container>
                    {fields.map((field, i, arr) => {
                        return (
                            <>
                                <Grid
                                    item
                                    xs={6}
                                    sm={3}
                                    style={
                                        field.has_changed
                                            ? {
                                                  backgroundColor:
                                                      "rgb(253, 237, 237)",
                                                  display: "flex",
                                                  paddingLeft: "5px",
                                              }
                                            : {}
                                    }
                                >
                                    {field.has_changed ? (
                                        <ErrorOutlineIcon
                                            style={{
                                                marginTop: "8px",
                                                marginRight: "5px",
                                                color: "rgb(239, 83, 80)",
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <p
                                        className={classes.fieldName}
                                        style={
                                            field.has_changed
                                                ? { color: "rgb(95, 33, 32)" }
                                                : {}
                                        }
                                    >
                                        {field.label}:
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sm={9}
                                    style={
                                        field.has_changed
                                            ? {
                                                  backgroundColor:
                                                      "rgb(253, 237, 237)",
                                              }
                                            : {}
                                    }
                                >
                                    <p
                                        style={{
                                            margin: "10px 0",
                                            color: field.has_changed
                                                ? "rgb(95, 33, 32)"
                                                : "",
                                        }}
                                    >
                                        {isMyBnRDateString(field.display_value)
                                            ? field.display_value.split("T")[0]
                                            : field.display_value}
                                    </p>
                                </Grid>

                                {i == arr.length - 1 ? (
                                    <></>
                                ) : (
                                    <Divider style={{ width: "100%" }} />
                                )}
                            </>
                        );
                    })}
                </Grid>
            );
        };

        return (
            <>
                {fields.length > 0 ? (
                    <Container>
                        <Typography
                            style={{
                                marginTop: 24,
                                fontSize: "18px",
                                fontWeight: 700,
                            }}
                        >
                            {title}
                        </Typography>
                        {handleOneColumnExceptions(title)}
                    </Container>
                ) : (
                    <Container>
                        <Grid container>
                            <Grid item>
                                <p style={{ margin: "10px 0" }}>
                                    No gear selection has been made by the
                                    traveller at this time...
                                </p>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Container>
                )}
            </>
        );
    };

    const TwoColumnLayout = ({ title, sectionFields, responseObject }) => {
        let fields = [];
        if (responseObject) {
            sectionFields.map((field) => {
                fields.push({
                    label: responseObject[field].label,
                    display_value: responseObject[field].display_value,
                    has_changed: responseObject[field].has_changed,
                });
            });
        }
        const handleTwoColumnExceptions = (title) => {
            switch (title) {
                case "Shipping Address":
                    let isSameAsHomeAddress = false;
                    if (
                        tifData.contact.address1_line1.display_value ==
                            tifData.contact.address2_line1.display_value &&
                        tifData.contact.address1_city.display_value ==
                            tifData.contact.address2_city.display_value
                    ) {
                        isSameAsHomeAddress = true;
                    }

                    if (isSameAsHomeAddress == true) {
                        return (
                            <FormControlLabel
                                disabled
                                control={
                                    <Checkbox checked name="sameAsShipping" />
                                }
                                label="Same as home address"
                            />
                        );
                    } else {
                        fields = fields.filter(
                            (field) => field.label !== "Same as home addres"
                        );
                        break;
                    }
                case "Second Emergency Contact":
                    let addSecondEmergencyContact = false;
                    if (
                        tifData.contact.p15_emergency2email.display_value !=
                            null &&
                        tifData.contact.p15_emergency2email.display_value !=
                            "" &&
                        tifData.contact.p15_emergency2name.display_value !=
                            null &&
                        tifData.contact.p15_emergency2name.display_value != ""
                    ) {
                        addSecondEmergencyContact = true;
                    }
                    if (addSecondEmergencyContact == false) {
                        return (
                            <FormControlLabel
                                disabled
                                control={
                                    <Checkbox
                                        checked={false}
                                        name="addSecondaryEmergencyContact"
                                    />
                                }
                                label="Add second emergency contact"
                            />
                        );
                    } else {
                        fields = fields.filter(
                            (field) =>
                                field.label !== "Add second emergency contact"
                        );
                        break;
                    }
                case "Waiver":
                    let waiverReceived = false;
                    if (
                        moment(
                            tifData.guest.br_datewaiverreceived.display_value
                        ).isValid()
                    ) {
                        waiverReceived = true;
                    }
                    if (waiverReceived == true) {
                        return (
                            <Container
                                style={{
                                    marginTop: "24px",
                                    padding: "0px",
                                    display: "flex",
                                    backgroundColor: tifData.guest
                                        .br_datewaiverreceived.has_changed
                                        ? "rgb(253, 237, 237)"
                                        : "",
                                }}
                            >
                                {tifData.guest.br_datewaiverreceived
                                    .has_changed ? (
                                    <ErrorOutlineIcon
                                        style={{
                                            marginTop: "8px",
                                            marginRight: "5px",
                                            color: "rgb(239, 83, 80)",
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                                <FormControlLabel
                                    disabled
                                    control={
                                        <Checkbox
                                            checked={true}
                                            name="addSecondaryEmergencyContact"
                                        />
                                    }
                                    label="Traveller has signed waiver"
                                />
                            </Container>
                        );
                    } else {
                        return (
                            <FormControlLabel
                                disabled
                                control={
                                    <Checkbox
                                        checked={false}
                                        name="addSecondaryEmergencyContact"
                                    />
                                }
                                label="Traveller has signed waiver"
                            />
                        );
                    }
                case "Privacy Policy + Terms and Conditons":
                    let agreedToTerms = false;
                    if (
                        moment(
                            tifData.contact.br_agreedtoprivacypolicydate
                                .display_value
                        ).isValid()
                    ) {
                        agreedToTerms = true;
                    }
                    if (agreedToTerms == true) {
                        return (
                            <Container
                                style={{
                                    marginTop: "24px",
                                    padding: "0px",
                                    display: "flex",
                                    backgroundColor: tifData.contact
                                        .br_agreedtoprivacypolicydate
                                        .has_changed
                                        ? "rgb(253, 237, 237)"
                                        : "",
                                }}
                            >
                                {tifData.contact.br_agreedtoprivacypolicydate
                                    .has_changed ? (
                                    <ErrorOutlineIcon
                                        style={{
                                            marginTop: "8px",
                                            marginRight: "5px",
                                            color: "rgb(239, 83, 80)",
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                                <FormControlLabel
                                    disabled
                                    control={
                                        <Checkbox
                                            checked={true}
                                            name="addSecondaryEmergencyContact"
                                        />
                                    }
                                    label="Traveller has agreed to privacy policy + terms and conditions"
                                />
                            </Container>
                        );
                    } else {
                        return (
                            <FormControlLabel
                                disabled
                                control={
                                    <Checkbox
                                        checked={false}
                                        name="addSecondaryEmergencyContact"
                                    />
                                }
                                label="Traveller has agreed to privacy policy + terms and conditions"
                            />
                        );
                    }
                default:
                    break;
            }

            return (
                <Grid container>
                    {fields.map((field, i) => {
                        return (
                            <>
                                <Grid
                                    item
                                    xs={6}
                                    sm={3}
                                    style={
                                        field.has_changed
                                            ? {
                                                  backgroundColor:
                                                      "rgb(253, 237, 237)",
                                                  display: "flex",
                                                  paddingLeft: "5px",
                                              }
                                            : {}
                                    }
                                >
                                    {field.has_changed ? (
                                        <ErrorOutlineIcon
                                            style={{
                                                marginTop: "8px",
                                                marginRight: "5px",
                                                color: "rgb(239, 83, 80)",
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <p
                                        className={classes.fieldName}
                                        style={
                                            field.has_changed
                                                ? { color: "rgb(95, 33, 32)" }
                                                : {}
                                        }
                                    >
                                        {field.label}:
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sm={3}
                                    style={
                                        field.has_changed
                                            ? {
                                                  backgroundColor:
                                                      "rgb(253, 237, 237)",
                                              }
                                            : {}
                                    }
                                >
                                    <p
                                        style={{
                                            margin: "10px 0",
                                            color: field.has_changed
                                                ? "rgb(95, 33, 32)"
                                                : "",
                                        }}
                                    >
                                        {isMyBnRDateString(field.display_value)
                                            ? field.display_value.split("T")[0]
                                            : field.display_value}
                                    </p>
                                </Grid>
                                {i % 2 !== 0 ? (
                                    <Divider style={{ width: "100%" }} />
                                ) : (
                                    <Divider
                                        className={classes.mobileDivider}
                                    />
                                )}
                            </>
                        );
                    })}
                </Grid>
            );
        };

        return (
            <>
                {fields.length > 0 ? (
                    <Container>
                        <Typography
                            style={{
                                marginTop: 24,
                                fontSize: "18px",
                                fontWeight: 700,
                            }}
                        >
                            {title}
                        </Typography>
                        {handleTwoColumnExceptions(title)}
                    </Container>
                ) : (
                    <Container>
                        <Grid container>
                            <Grid item>
                                <p style={{ margin: "10px 0" }}>
                                    Could not access contact details, please try
                                    again later.
                                </p>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Container>
                )}
            </>
        );
    };

    const refreshPageDate = () => {
        api(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getUpcomingTrips/contact/${contactid}/departure/${departureid}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((departureResponse) => {
                if (departureResponse.success !== false) {
                    setUpcomingTripDetails(departureResponse);
                }
            })
            .catch((e) => console.log(e));
        api(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getContacts/${contactid}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
            // requestObject
        )
            .then((contactResponse) => {
                if (contactResponse.success !== false) {
                    setContactDetails(contactResponse);
                    setIsP15ContactSyncing(contactResponse.is_p15_syncing);
                }
            })
            .catch((e) => console.log(e));
        api(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/getDeparture/${departureid}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((departureResponse) => {
                if (departureResponse.success !== false) {
                    setDepartureDetails(departureResponse);
                }
            })
            .catch((e) => console.log(e));

        api(`${process.env.REACT_APP_MY_API_ENDPOINT}/v1/getGuest/${guestid}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((guestResponse) => {
                if (guestResponse.success !== false) {
                    setGuestDetails(guestResponse);
                    setIsP15GuestSyncing(guestResponse.is_p15_syncing);
                }
            })
            .catch((e) => console.log(e));

        api(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getAnnotations/guest/${guestid}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((annotationsResponse) => {
                if (annotationsResponse.success == true) {
                    setAnnotationsData(annotationsResponse.data);
                }
            })
            .catch((e) => console.log(e));
    };

    const fetchTif = () => {
        api(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getTif/guest/${guestid}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((tifData) => {
                setTifData(tifData.data.generate_tif);
            })
            .catch((e) => console.log(e));
    };

    const fetchGearSyncData = () => {
        api(
            `https://endpoint.scribesoft.com/v1/orgs/3487/requests/${scribeEndpoints["populateGearTempTable"]}?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: {
                    dbEnvironment: process.env.REACT_APP_MY_API_ENDPOINT,
                    guest_id: guestid,
                },
            }
        )
            .then((res) => {
                if (res.data[0].success) {
                    api(
                        `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getGearSyncData/guest/${guestid}`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                        .then((gearSyncData) => {
                            setGearSyncData(
                                gearSyncData.data.fn_generate_gear_sync_data
                            );
                            if (
                                gearSyncData.data.fn_generate_gear_sync_data
                                    .to_delete.length === 0 &&
                                gearSyncData.data.fn_generate_gear_sync_data
                                    .to_insert.length === 0 &&
                                (upcomingTripDetails?.getUpcomingTrip[0]
                                    .is_p15_syncing.gear === true ||
                                    upcomingTripDetails?.getUpcomingTrip[0]
                                        .is_p15_syncing.gear === null)
                            ) {
                                setIsP15GearSyncing(true);
                            } else {
                                setIsP15GearSyncing(false);
                            }
                        })
                        .catch((e) => console.log(e));
                }
                /*
                ##################

                TODO:
                NEED ELSE CONDITION TO PURGE IN EVENT OF FAILURE (OR ADD TO SCRIBE)
               
                ##################
                */
            })
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        fetchTif();
        refreshPageDate();
    }, []);

    useEffect(() => {
        if (upcomingTripDetails) {
            fetchGearSyncData();
        }
    }, [upcomingTripDetails]);

    return (
        <Container maxWidth={"md"}>
            <Paper
                style={{
                    padding: "2rem 3rem",
                    marginTop: "2rem",
                    marginBottom: "5rem",
                }}
            >
                {/* <Box style={{ marginBottom: "16px" }}>
                    <Button
                        onClick={handleBackClick}
                        size="small"
                        variant="contained"
                    >
                        Back
                    </Button>
                </Box> */}
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <img
                            src="https://www.butterfield.com/wp-content/themes/butterfield/images/br-logo.svg"
                            alt="logo"
                            className={classes.image}
                        />
                    </Grid>
                    {contactDetails &&
                    upcomingTripDetails &&
                    tifData &&
                    gearSyncData ? (
                        <Grid item xs={12} md={9} style={{}}>
                            <Typography
                                variant="h4"
                                className={classes.subTitle}
                            >
                                TIF Overview for {contactDetails?.firstname}{" "}
                                {contactDetails?.lastname}
                            </Typography>
                            <Typography variant="h3" className={classes.title}>
                                {
                                    upcomingTripDetails?.getUpcomingTrip[0]
                                        ?.p15_tripname
                                }
                            </Typography>
                            <Typography
                                variant="h6"
                                className={classes.dateTitle}
                                gutterBottom
                            >
                                {
                                    upcomingTripDetails?.getUpcomingTrip[0]
                                        ?.p15_startdate
                                }{" "}
                                -{" "}
                                {
                                    upcomingTripDetails?.getUpcomingTrip[0]
                                        ?.p15_enddate
                                }
                            </Typography>
                            {error ? (
                                <Box my={2}>
                                    <Alert severity="error">{error}</Alert>
                                </Box>
                            ) : (
                                <></>
                            )}
                            <Grid container>
                                {isP15ContactImporting ||
                                isP15GuestImporting ||
                                isP15GearImporting ? (
                                    <Grid item>
                                        <Chip
                                            label={"Importing..."}
                                            color="primary"
                                            icon={<SyncIcon />}
                                        />
                                    </Grid>
                                ) : isP15ContactSyncing &&
                                  isP15GuestSyncing &&
                                  isP15GearSyncing ? (
                                    <Grid item>
                                        <Chip
                                            label={"Syncing with P15"}
                                            color="primary"
                                            icon={<DoneIcon />}
                                        />
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid>
                                            <Chip
                                                label={"Edited in My B&R"}
                                                color="primary"
                                                icon={<FaceIcon />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                style={{ marginLeft: "5px" }}
                                                size="small"
                                                variant="outlined"
                                                color="primary"
                                                onClick={handleSyncClick}
                                            >
                                                Sync to P15
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ) : (
                        <>Loading traveller information...</>
                    )}
                </Grid>
                <Box style={{ marginTop: "16px" }}>
                    <Divider />
                </Box>
                {contactDetails &&
                upcomingTripDetails &&
                tifData &&
                gearSyncData ? (
                    <>
                        <TwoColumnLayout
                            title="Privacy Policy + Terms and Conditons"
                            sectionFields={adminNameFields}
                            responseObject={tifData.contact}
                        />
                        {tifData.equipment ? (
                            <>
                                <Typography
                                    variant="h4"
                                    className={classes.sectionTitle}
                                >
                                    Gear Selection:
                                </Typography>

                                <OneColumnLayout
                                    title=""
                                    sectionFields={activeEquipmentFields()}
                                    responseObject={tifData.equipment}
                                />
                            </>
                        ) : (
                            <></>
                        )}

                        <Typography
                            variant="h4"
                            className={classes.sectionTitle}
                        >
                            Contact Information:
                        </Typography>

                        <TwoColumnLayout
                            title="Name"
                            sectionFields={contactNameFields}
                            responseObject={tifData.contact}
                        />
                        <TwoColumnLayout
                            title="Phone Number"
                            sectionFields={contactPhoneFields}
                            responseObject={tifData.contact}
                        />
                        <TwoColumnLayout
                            title="Home Address"
                            sectionFields={contactHomeAddressFields}
                            responseObject={tifData.contact}
                        />
                        <TwoColumnLayout
                            title="Shipping Address"
                            sectionFields={contactShippingAddressFields}
                            responseObject={tifData.contact}
                        />
                        <TwoColumnLayout
                            title="Emergency Contact"
                            sectionFields={contactEmergencyContact1Fields}
                            responseObject={tifData.contact}
                        />
                        <TwoColumnLayout
                            title="Second Emergency Contact"
                            sectionFields={contactEmergencyContact2Fields}
                            responseObject={tifData.contact}
                        />
                        <Container
                            style={{
                                marginTop: "24px",
                                display: "flex",
                                backgroundColor: tifData.guest
                                    .br_confirmedvalidcontactinfo.has_changed
                                    ? "rgb(253, 237, 237)"
                                    : "",
                            }}
                        >
                            {tifData.guest.br_confirmedvalidcontactinfo
                                .has_changed ? (
                                <ErrorOutlineIcon
                                    style={{
                                        marginTop: "8px",
                                        marginRight: "5px",
                                        color: "rgb(239, 83, 80)",
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            <FormControlLabel
                                disabled
                                control={
                                    <Checkbox
                                        checked={
                                            tifData.guest
                                                .br_confirmedvalidcontactinfo
                                                .display_value
                                        }
                                        name="sameAsShipping"
                                    />
                                }
                                label="Traveller has agreed that the contact information above is correct"
                            />
                        </Container>

                        <Typography
                            variant="h4"
                            className={classes.sectionTitle}
                        >
                            Traveller Profile
                        </Typography>
                        <TwoColumnLayout
                            title="Waiver"
                            sectionFields={guestWaiverFields}
                            responseObject={tifData.guest}
                        />
                        <TwoColumnLayout
                            title="Personal Details"
                            sectionFields={contactPersonalDetailsFields}
                            responseObject={tifData.contact}
                        />
                        <OneColumnLayout
                            title="Health Details"
                            sectionFields={contactHealthDetailsFields}
                            responseObject={tifData.contact}
                        />
                        <TwoColumnLayout
                            title="Passport"
                            sectionFields={contactPassportDetailsFields}
                            responseObject={tifData.contact}
                        />
                        <Container
                            style={{
                                marginTop: "24px",
                                display: "flex",
                                backgroundColor: tifData.guest
                                    .br_travellingwithpassportonrecord
                                    .has_changed
                                    ? "rgb(253, 237, 237)"
                                    : "",
                            }}
                        >
                            {tifData.guest.br_travellingwithpassportonrecord
                                .has_changed ? (
                                <ErrorOutlineIcon
                                    style={{
                                        marginTop: "8px",
                                        marginRight: "5px",
                                        color: "rgb(239, 83, 80)",
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            <FormControlLabel
                                disabled
                                control={
                                    <Checkbox
                                        checked={
                                            tifData.guest
                                                .br_travellingwithpassportonrecord
                                                .display_value
                                        }
                                        name="sameAsShipping"
                                    />
                                }
                                label="Traveller has confirmed that the passport information above is correct"
                            />
                        </Container>
                        {departureDetails.br_passportuploadflag ? (
                            <Container
                                style={{
                                    marginTop: "24px",
                                    display: "flex",
                                    // backgroundColor: tifData.guest
                                    //     .br_travellingwithpassportonrecord
                                    //     .has_changed
                                    //     ? "rgb(253, 237, 237)"
                                    //     : "",
                                }}
                            >
                                <FormControlLabel
                                    disabled
                                    control={
                                        <Checkbox
                                            checked={
                                                annotationsData
                                                    ? where(annotationsData, {
                                                          subject: "PASSPORT",
                                                      }).length > 0
                                                    : false
                                            }
                                            name="passport_upload"
                                        />
                                    }
                                    label="Traveller has uploaded passport scan through My B&R"
                                />
                            </Container>
                        ) : (
                            <></>
                        )}
                        {departureDetails.br_visaflag ? (
                            <>
                                <OneColumnLayout
                                    title="Travel Visa"
                                    sectionFields={guestTravelVisaDetailsFields}
                                    responseObject={tifData.guest}
                                />
                                <Container
                                    style={{
                                        marginTop: "24px",
                                        display: "flex",
                                        // backgroundColor: tifData.guest
                                        //     .br_travellingwithpassportonrecord
                                        //     .has_changed
                                        //     ? "rgb(253, 237, 237)"
                                        //     : "",
                                    }}
                                >
                                    <FormControlLabel
                                        disabled
                                        control={
                                            <Checkbox
                                                checked={
                                                    annotationsData
                                                        ? where(
                                                              annotationsData,
                                                              {
                                                                  subject:
                                                                      "VISA",
                                                              }
                                                          ).length > 0
                                                        : false
                                                }
                                                name="visa_upload"
                                            />
                                        }
                                        label="Traveller has uploaded visa scan through My B&R"
                                    />
                                </Container>
                            </>
                        ) : (
                            <></>
                        )}
                        <Typography
                            variant="h4"
                            className={classes.sectionTitle}
                        >
                            Arrival & Departure
                        </Typography>
                        <OneColumnLayout
                            title="Pre/ Post Plans"
                            sectionFields={guestPrePostDetailsFields}
                            responseObject={tifData.guest}
                        />
                        {departureDetails.br_flightsflag ? (
                            <>
                                {guestDetails.br_selected_arrival_transportation_method ==
                                100000001 ? (
                                    <TwoColumnLayout
                                        title="Arrival Flight"
                                        sectionFields={
                                            guestArrivalFlightDetailsFields
                                        }
                                        responseObject={tifData.guest}
                                    />
                                ) : (
                                    <></>
                                )}

                                {guestDetails.br_selected_departure_transportation_method ==
                                100000001 ? (
                                    <TwoColumnLayout
                                        title="Departure Flight"
                                        sectionFields={
                                            guestDepartureFlightDetailsFields
                                        }
                                        responseObject={tifData.guest}
                                    />
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                        {departureDetails.br_trainsflag ? (
                            <>
                                {guestDetails.br_selected_arrival_transportation_method ==
                                100000000 ? (
                                    <TwoColumnLayout
                                        title="Train Details - Arrival"
                                        sectionFields={
                                            guestArrivalTrainDetailsFields
                                        }
                                        responseObject={tifData.guest}
                                    />
                                ) : (
                                    <></>
                                )}
                                {guestDetails.br_selected_departure_transportation_method ==
                                100000000 ? (
                                    <TwoColumnLayout
                                        title="Train Details - Departure"
                                        sectionFields={
                                            guestDepartureTrainDetailsFields
                                        }
                                        responseObject={tifData.guest}
                                    />
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                        {/* <Box my={2}>
                    <Divider />
                </Box> */}
                        {upcomingTripDetails.getUpcomingTrip[0]?.bookingID ? (
                            <>
                                <Typography
                                    variant="h4"
                                    className={classes.sectionTitle}
                                >
                                    Payment & Insurance
                                </Typography>
                                <Container
                                    style={{
                                        marginTop: "24px",
                                        display: "flex",
                                        backgroundColor: tifData.guest
                                            .br_agreedtotermsandconditions
                                            .has_changed
                                            ? "rgb(253, 237, 237)"
                                            : "",
                                    }}
                                >
                                    {tifData.guest.br_agreedtotermsandconditions
                                        .has_changed ? (
                                        <ErrorOutlineIcon
                                            style={{
                                                marginTop: "8px",
                                                marginRight: "5px",
                                                color: "rgb(239, 83, 80)",
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <FormControlLabel
                                        disabled
                                        control={
                                            <Checkbox
                                                checked={
                                                    tifData.guest
                                                        .br_agreedtotermsandconditions
                                                        .display_value
                                                }
                                                name="sameAsShipping"
                                            />
                                        }
                                        label="Traveller has read, understood, and agreed to the terms and conditions on their invoice."
                                    />
                                </Container>

                                <OneColumnLayout
                                    title="Final Payment"
                                    sectionFields={
                                        guestFinalPaymentDetailsFields
                                    }
                                    responseObject={tifData.guest}
                                />
                                <OneColumnLayout
                                    title="Insurance"
                                    sectionFields={guestInsuranceDetailsFields}
                                    responseObject={tifData.guest}
                                />
                                {/* <Box my={2}>
                        <Divider />
                </Box> */}
                            </>
                        ) : (
                            <></>
                        )}
                        <Typography
                            variant="h4"
                            className={classes.sectionTitle}
                        >
                            Other Information and Special Requests
                        </Typography>
                        <OneColumnLayout
                            title="Additional Comments"
                            sectionFields={guestCommentDetailsFields}
                            responseObject={tifData.guest}
                        />
                    </>
                ) : (
                    <></>
                )}
            </Paper>
        </Container>
    );
};

export default TifViewer;
