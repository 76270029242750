import React from "react";

const TabPanel = (props) => {
    const { children, tabValue, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={tabValue !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {tabValue === index && <>{children}</>}
        </div>
    );
};

export default TabPanel;
