import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import TextField from "@material-ui/core/TextField";

const DebouncedTextInput = ({
    onDebouncedChange,
    value,
    label,
    placeholder,
}) => {
    const [input, setInput] = useState(value || "");

    const [debouncedInput] = useDebounce(input, 500);

    useEffect(() => {
        if (!onDebouncedChange) return;
        onDebouncedChange(input);
    }, [debouncedInput]);

    return (
        <TextField
            id="outlined-basic"
            label={label || "No label provided"}
            placeholder={placeholder || "No placeholder provided"}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            fullWidth
            autoComplete="off"
        />
    );
};

export default DebouncedTextInput;
