import * as React from "react";
import { DataGrid as MuiXDataGrid } from "@mui/x-data-grid";
import { Button, Chip, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";

function getStatusChipProps(params) {
    if (!params.row.departure_sections_status) {
        return {
            icon: (
                <WarningIcon
                    style={{
                        fill: "rgb(171, 104, 0)",
                    }}
                    label={"Partially Filled"}
                />
            ),
            label: "Partially Filled",
            style: {
                borderColor: "rgb(171, 104, 0)",
                color: "rgb(171, 104, 0)",
                width: "100%",
            },
        };
    }
    if (params.row.departure_sections_status) {
        return {
            icon: (
                <DoneAllIcon
                    style={{
                        fill: "green",
                    }}
                    label={"Complete"}
                />
            ),
            label: "Complete",
            style: {
                borderColor: "green",
                color: "green",
                width: "100%",
            },
        };
    }
}

function getSyncChipProps(params) {
    if (params.row.departure_sync_status === false) {
        return {
            icon: (
                <SyncProblemIcon
                    style={{
                        fill: "#af0000",
                    }}
                    label={"Records Out of Sync"}
                />
            ),
            label: "Records Out of Sync",
            style: {
                borderColor: "#af0000",
                color: "#af0000",
                width: "100%",
            },
        };
    } else {
        return {
            icon: (
                <DoneAllIcon
                    style={{
                        fill: "green",
                    }}
                    label={"All Records Syncing"}
                />
            ),
            label: "All Records Syncing",
            style: {
                borderColor: "green",
                color: "green",
                width: "100%",
            },
        };
    }
}

function getGuestStatusChipProps(params) {
    if (params.row[params.field] === false) {
        return {
            icon: (
                <WarningIcon
                    style={{
                        fill: "rgb(171, 104, 0)",
                    }}
                    label={params.row.syncStatus}
                />
            ),
            label: "Incomplete",
            style: {
                borderColor: "rgb(171, 104, 0)",
                color: "rgb(171, 104, 0)",
                width: "100%",
            },
        };
    }

    return {
        icon: (
            <DoneAllIcon
                style={{
                    fill: "green",
                }}
                label={params.row.syncStatus}
            />
        ),
        label: "Complete",
        style: {
            borderColor: "green",
            color: "green",
            width: "100%",
        },
    };
}

function getGuestSyncStatusChipProps(params) {
    if (params.row[params.field] === false) {
        return {
            icon: (
                <SyncProblemIcon
                    style={{
                        fill: "#af0000",
                    }}
                    label={params.row.syncStatus}
                />
            ),
            label: "Not Syncing",
            style: {
                borderColor: "#af0000",
                color: "#af0000",
                width: "100%",
            },
        };
    }

    return {
        icon: (
            <DoneAllIcon
                style={{
                    fill: "#194666",
                }}
                label={params.row.syncStatus}
            />
        ),
        label: "Syncing",
        style: {
            borderColor: "#194666",
            color: "#194666",
            width: "100%",
        },
    };
}

export default function DataGrid({
    isLoading,
    selectedDepartureId,
    guestsList,
    departureList,
    setSelectedGuestId,
    setSelectedContactId,
    setModalHeading,
    setIsModalOpen,
    handleViewGuestsClick,
}) {
    const departuresColumns = [
        {
            field: "p15_tripname",
            headerName: "Departure",
            width: 400,
            renderCell: (params) => (
                <>
                    {params.row.p15_tripname} | {params.row.p15_departurecode}
                </>
            ),
            // editable: true,
        },
        {
            field: "departure_sections_status",
            headerName: "Status",
            width: 150,
            type: "singleSelect",
            valueOptions: [
                { label: "Complete", value: true },
                { label: "Partially Filled", value: false },
            ],
            renderCell: (params) => {
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        {...getStatusChipProps(params)}
                    />
                );
            },
        },
        {
            field: "departure_sync_status",
            headerName: "Peak15 Sync Status",
            width: 210,
            type: "singleSelect",
            valueOptions: [
                { label: "All Records Syncing", value: true },
                { label: "Records Out of Sync", value: false },
            ],
            renderCell: (params) => {
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        {...getSyncChipProps(params)}
                    />
                );
            },
        },
        {
            field: "p15_departurecode",
            headerName: " ",
            width: 175,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ width: "80%", maxHeight: "25px" }}
                        onClick={() => {
                            handleViewGuestsClick(
                                params.row.p15_tripdeparturesid
                            );
                        }}
                    >
                        View Guests
                    </Button>
                );
            },
        },
        {
            field: "travel_advisor",
            headerName: "Travel Advisor",
            width: 200,
        },
        {
            field: "travel_coordinator",
            headerName: "Travel Coordinator",
            width: 200,
        },
        {
            field: "p15_clientidname",
            headerName: "Ringleader",
            width: 200,
        },
        {
            field: "regional_director",
            headerName: "Regional Director",
            width: 200,
        },
        {
            field: "trip_manager",
            headerName: "Trip Manager",
            width: 200,
        },
        {
            field: "p15_bookedguestcount",
            headerName: "PAX",
            type: "number",
            width: 110,
        },
        // {
        //     field: "p15_startdate",
        //     headerName: "Start Date",
        //     width: 175,
        //     valueGetter: (params) => {
        //         return new Date(params.row.p15_startdate)
        //             .toISOString()
        //             .split("T")[0];
        //     },
        // },
        // {
        //     field: "p15_enddate",
        //     headerName: "End Date",
        //     width: 175,
        //     valueGetter: (params) => {
        //         return new Date(params.row.p15_enddate)
        //             .toISOString()
        //             .split("T")[0];
        //     },
        // },
    ];

    const guestsColumns = [
        {
            field: "p15_name",
            headerName: "Traveller",
            width: 275,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ width: "100%", maxHeight: "25px" }}
                        onClick={() => {
                            setSelectedGuestId(params.row.p15_guestsid);
                            setSelectedContactId(
                                params.row.p15_contactguestsid
                            );
                            setModalHeading(
                                `${params.row.p15_name} - ${params.row.departure_name}`
                            );

                            setIsModalOpen(true);
                        }}
                    >
                        {params.row.p15_name}
                    </Button>
                );
            },
        },
        {
            field: "gear_section",
            headerName: "Gear",
            width: 150,
            type: "singleSelect",
            valueOptions: [
                { label: "Complete", value: true },
                { label: "Partially Filled", value: false },
            ],
            renderCell: (params) => {
                console.log(params);
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        {...getGuestStatusChipProps({
                            field: "gear_section",
                            ...params,
                        })}
                    />
                );
            },
        },
        {
            field: "contact_section",
            headerName: "Contact Info",
            width: 175,
            type: "singleSelect",
            valueOptions: [
                { label: "Complete", value: true },
                { label: "Partially Filled", value: false },
            ],
            renderCell: (params) => {
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        {...getGuestStatusChipProps({
                            field: "contact_section",
                            ...params,
                        })}
                    />
                );
            },
        },
        {
            field: "traveller_profile_section",
            headerName: "Traveller Profile",
            width: 200,
            type: "singleSelect",
            valueOptions: [
                { label: "Complete", value: true },
                { label: "Partially Filled", value: false },
            ],
            renderCell: (params) => {
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        {...getGuestStatusChipProps({
                            field: "traveller_profile_section",
                            ...params,
                        })}
                    />
                );
            },
        },
        {
            field: "arrival_and_departure_section",
            headerName: "Arrival & Departure",
            width: 200,
            type: "singleSelect",
            valueOptions: [
                { label: "Complete", value: true },
                { label: "Partially Filled", value: false },
            ],
            renderCell: (params) => {
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        {...getGuestStatusChipProps({
                            field: "arrival_and_departure_section",
                            ...params,
                        })}
                    />
                );
            },
        },
        {
            field: "payment_and_insurance_section",
            headerName: "Payment & Insurance",
            width: 225,
            type: "singleSelect",
            valueOptions: [
                { label: "Complete", value: true },
                { label: "Partially Filled", value: false },
            ],
            renderCell: (params) => {
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        {...getGuestStatusChipProps({
                            field: "payment_and_insurance_section",
                            ...params,
                        })}
                    />
                );
            },
        },
        {
            field: "contact_sync",
            headerName: "Contact",
            description: "This column has a value getter and is not sortable.",
            sortable: false,
            width: 150,
            type: "singleSelect",
            valueOptions: [
                { label: "All Records Syncing", value: true },
                { label: "Records Out of Sync", value: false },
            ],
            renderCell: (params) => {
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        {...getGuestSyncStatusChipProps({
                            field: "contact_sync",
                            ...params,
                        })}
                    />
                );
            },
        },
        {
            field: "guest_sync",
            headerName: "Guest",
            description: "This column has a value getter and is not sortable.",
            sortable: false,
            width: 150,
            type: "singleSelect",
            valueOptions: [
                { label: "All Records Syncing", value: true },
                { label: "Records Out of Sync", value: false },
            ],
            renderCell: (params) => {
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        {...getGuestSyncStatusChipProps({
                            field: "guest_sync",
                            ...params,
                        })}
                    />
                );
            },
        },
        {
            field: "gear_sync",
            headerName: "Gear",
            description: "This column has a value getter and is not sortable.",
            sortable: false,
            width: 150,
            type: "singleSelect",
            valueOptions: [
                { label: "All Records Syncing", value: true },
                { label: "Records Out of Sync", value: false },
            ],
            renderCell: (params) => {
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        {...getGuestSyncStatusChipProps({
                            field: "gear_sync",
                            ...params,
                        })}
                    />
                );
            },
        },
    ];

    const departureRows = departureList
        .sort(
            (dep1, dep2) =>
                new Date(dep1.p15_startdate) - new Date(dep2.p15_startdate)
        )
        .map((departure, i) => {
            return {
                id: i,
                status: i % 4 === 0 ? "Partially Filled" : "Complete",
                syncStatus:
                    i % 6 === 0 ? "Records Out of Sync" : "All Records Syncing",
                ...departure,
            };
        });

    const guestRows = guestsList.map((guest, i) => {
        return {
            id: i,
            status: i % 4 === 0 ? "Partially Filled" : "Complete",
            syncStatus:
                i % 6 === 0 ? "Records Out of Sync" : "All Records Syncing",
            ...guest,
        };
    });

    return (
        <div style={{ width: "100%" }}>
            {selectedDepartureId ? (
                <>
                    <MuiXDataGrid
                        loading={isLoading.guests}
                        rows={guestRows}
                        columns={guestsColumns}
                        pageSize={15}
                        rowsPerPageOptions={[50]}
                        // checkboxSelection
                        disableSelectionOnClick
                        autoHeight={true}
                        density="compact"
                    />
                    <Button
                        onClick={() => handleViewGuestsClick(null)}
                        variant="contained"
                    >
                        Back
                    </Button>
                </>
            ) : (
                <MuiXDataGrid
                    loading={isLoading.departures}
                    rows={departureRows}
                    columns={departuresColumns}
                    pageSize={15}
                    rowsPerPageOptions={[50]}
                    // checkboxSelection
                    disableSelectionOnClick
                    autoHeight={true}
                    density="compact"
                />
            )}
        </div>
    );
}
