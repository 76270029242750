import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Divider, makeStyles } from "@material-ui/core";
import {
    Box,
    Container,
    Grid,
    Paper,
    Tooltip,
    Tabs,
    Tab,
    Typography,
} from "@material-ui/core";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";

import TifViewerUpcomingDepartures from "../components/cells/TifViewerUpcomingDepartures";
import IframeModal from "../presentational/IFrameModal";
import TifViewer from "./TifViewer";
import DebouncedTextInput from "../components/_atoms/DebouncedTextInput";
import TabPanel from "../components/_molecules/TabPanel";
import DataGrid from "../components/cells/DataGrid";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: "32px",
        fontWeight: 700,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        textTransform: "none",
    },
    subTitle: {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        textTransform: "none",
    },
    sectionTitle: {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        textTransform: "none",
        marginTop: 32,
    },
    fieldName: {
        fontWeight: 700,
        margin: "10px 0",
    },
    fullList: {
        width: "auto",
    },
    list: {
        width: "100%",
    },
    link: {
        textDecoration: "none",
    },
    drawer: {
        textDecoration: "none",
        color: "black",
    },
    image: {
        maxWidth: "90%",
        height: "auto",
        margin: "auto",
    },
    complete: {
        color: "green",
    },
    incomplete: {
        color: "red",
    },
    alert: {
        color: "#ff8d00",
    },
}));

const TifSearch = (props) => {
    const classes = useStyles();
    let history = useHistory();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeContactName, setActiveContactName] = useState(null);
    const [modalHeading, setModalHeading] = useState(null);
    const [originalContactsList, setOriginalContactsList] = useState(null);
    const [contactsList, setContactsList] = useState(null);
    const [upcomingTripsList, setUpcomingTripsList] = useState(null);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [selectedGuestId, setSelectedGuestId] = useState(null);
    const [selectedDepartureId, setSelectedDepartureId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [originalDeparturesList, setOriginalDeparturesList] = useState([]);
    const [departureList, setDepartureList] = useState([]);
    const [guestsList, setGuestsList] = useState([]);

    const [isLoading, setIsLoading] = useState({
        departures: true,
        guests: true,
    });

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const fetchNonSyncingContacts = async () => {
        const nonSyncingContactsRes = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getContacts`,
            {
                method: "GET",

                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        const nonSyncingContactsData = await nonSyncingContactsRes.json();

        if (nonSyncingContactsData.error) {
            localStorage.clear();
            history.push("/");
            return;
        }

        setOriginalContactsList(nonSyncingContactsData);
        setContactsList(nonSyncingContactsData);
    };

    const fetchDepartures = async () => {
        setIsLoading({ ...isLoading, departures: true });
        const departuresRes = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getDepartures`,
            {
                method: "GET",

                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        const departuresData = await departuresRes.json();

        setOriginalDeparturesList(departuresData.departures);
        setDepartureList(departuresData.departures);
        setIsLoading({ ...isLoading, departures: false });
    };

    const fetchDeparturesByActiveContact = async () => {
        const departuresRes = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getUpcomingTrips/contact/${selectedContactId}`,
            {
                method: "GET",

                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        const departuresData = await departuresRes.json();

        console.log(departuresData);
        setUpcomingTripsList(departuresData);
    };

    const fetchGuestsByActiveDeparture = async () => {
        setGuestsList([]);
        setIsLoading({ ...isLoading, guests: true });
        const guestsRes = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getGuestsByDepartureId/${selectedDepartureId}`,
            {
                method: "GET",

                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        const guestsData = await guestsRes.json();

        console.log(guestsData);
        setGuestsList(guestsData.guests);
        setIsLoading({ ...isLoading, guests: false });
    };

    const filterContactList = (searchTerm) => {
        let filteredContactsList = originalContactsList?.contacts;
        // Filtering process below
        if ((searchTerm.trim() || "") != "") {
            filteredContactsList = filteredContactsList.filter((contact) => {
                let shouldContactBeReturned;

                let tagArray = [
                    contact.firstname,
                    contact.lastname,
                    contact.emailaddress1,
                ];

                let tagCloud = tagArray.join(" ");

                shouldContactBeReturned = tagCloud
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());

                return shouldContactBeReturned;
            });
            setContactsList({ contacts: filteredContactsList });
        } else {
            setContactsList(originalContactsList);
        }
    };

    const filterDepartureList = (searchTerm) => {
        let filteredDeparturesList = originalDeparturesList;
        // Filtering process below
        if ((searchTerm.trim() || "") != "") {
            filteredDeparturesList = filteredDeparturesList.filter(
                (departure) => {
                    let shouldDepartureBeReturned;

                    let tagArray = [
                        departure.p15_tripname,
                        departure.p15_departurecode,
                        departure.regional_director,
                        departure.trip_manager,
                        departure.travel_advisor,
                        departure.travel_coordinator,
                        departure.p15_clientidname,
                    ];

                    let tagCloud = tagArray.join(" ");

                    shouldDepartureBeReturned = tagCloud
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase());

                    return shouldDepartureBeReturned;
                }
            );
            setDepartureList(filteredDeparturesList);
        } else {
            setDepartureList(originalDeparturesList);
        }
    };

    const handleViewTripsClick = (newValue) => {
        if (newValue) {
            setSelectedContactId(newValue.contactid);
            setActiveContactName(`${newValue.firstname} ${newValue.lastname}`);
        } else {
            setSelectedContactId(null);
            setUpcomingTripsList(null);
            setActiveContactName(null);
        }
    };

    const handleViewGuestsClick = (departureId) => {
        if (departureId) {
            setSelectedDepartureId(departureId);
        } else {
            setSelectedDepartureId(null);
        }
    };

    useEffect(() => {
        fetchNonSyncingContacts();
    }, []);

    useEffect(() => {
        fetchDepartures();
    }, []);

    useEffect(() => {
        if (!selectedDepartureId) return;
        fetchGuestsByActiveDeparture();
    }, [selectedDepartureId]);

    useEffect(() => {
        if (!selectedContactId) return;
        fetchDeparturesByActiveContact();
    }, [selectedContactId]);

    return (
        <Container maxWidth={"xl"}>
            <Paper style={{ padding: "2rem 3rem 0.5rem" }}>
                <Typography
                    component="h1"
                    variant="h2"
                    style={{ fontSize: "2rem" }}
                >
                    TIF Viewer
                </Typography>
                <Grid container>
                    {tabValue === 0 && (
                        <Grid item xs={12} style={{}}>
                            <DebouncedTextInput
                                onDebouncedChange={filterContactList}
                                value={searchTerm}
                                label="Find a recently updated contacts"
                                placeholder="Search by traveller name or email..."
                            />
                        </Grid>
                    )}
                    {tabValue === 1 && (
                        <Grid item xs={12} style={{}}>
                            <DebouncedTextInput
                                onDebouncedChange={filterDepartureList}
                                value={searchTerm}
                                label="Find a departure"
                                placeholder="Search by trip name, departure code, RD, TC, or TA"
                            />
                        </Grid>
                    )}
                </Grid>
                <>
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        aria-label="disabled tabs example"
                        variant="fullWidth"
                    >
                        <Tab label="Recently Updated Travellers" />
                        <Tab label="Upcoming Departures" />
                        {/* <Tab label="X Data Grid" /> */}
                    </Tabs>

                    <TabPanel tabValue={tabValue} index={0}>
                        {contactsList ? (
                            !selectedContactId ? (
                                <>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <p>
                                                <strong>Traveller Name</strong>
                                            </p>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <p>
                                                <strong>Traveller Email</strong>
                                            </p>
                                        </Grid>
                                    </Grid>
                                    {contactsList.contacts
                                        .sort((a, b) => {
                                            let fa = a.firstname.toLowerCase(),
                                                fb = b.firstname.toLowerCase();

                                            if (fa < fb) {
                                                return -1;
                                            }
                                            if (fa > fb) {
                                                return 1;
                                            }
                                            return 0;
                                        })
                                        .map((contact) => {
                                            return (
                                                <>
                                                    <Box
                                                        style={{
                                                            margin: "4px",
                                                        }}
                                                    >
                                                        <Divider />
                                                    </Box>
                                                    <Grid container>
                                                        <Grid item xs={4}>
                                                            <p>
                                                                {
                                                                    contact.firstname
                                                                }{" "}
                                                                {
                                                                    contact.lastname
                                                                }
                                                            </p>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <p>
                                                                {
                                                                    contact.emailaddress1
                                                                }
                                                            </p>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Button
                                                                onClick={() =>
                                                                    handleViewTripsClick(
                                                                        contact
                                                                    )
                                                                }
                                                                variant="contained"
                                                                size="small"
                                                            >
                                                                View Trips
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            );
                                        })}
                                </>
                            ) : (
                                <>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <p>
                                                <strong>Status</strong>
                                            </p>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <p>
                                                <strong>Trip</strong>
                                            </p>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <p>
                                                <strong>Travel Dates</strong>
                                            </p>
                                        </Grid>
                                    </Grid>
                                    {upcomingTripsList ? (
                                        <>
                                            {upcomingTripsList.getUpcomingTrips
                                                .sort((a, b) => {
                                                    let da = new Date(
                                                        a.p15_startdate
                                                    );
                                                    let db = new Date(
                                                        b.p15_startdate
                                                    );
                                                    return da - db;
                                                })
                                                .map((trip) => {
                                                    return (
                                                        <>
                                                            <Box
                                                                style={{
                                                                    margin: "4px",
                                                                }}
                                                            >
                                                                <Divider />
                                                            </Box>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                >
                                                                    <p>
                                                                        {trip
                                                                            .is_p15_syncing
                                                                            .contact ? (
                                                                            <Tooltip title="Contact record is up to date and syncing with P15">
                                                                                <CheckCircleOutlineIcon
                                                                                    className={
                                                                                        classes.complete
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title="Contact record has been modified by My B&R and is not syncing with P15">
                                                                                <HighlightOffIcon
                                                                                    className={
                                                                                        classes.incomplete
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                        {trip
                                                                            .is_p15_syncing
                                                                            .guest ? (
                                                                            <Tooltip title="Guest record is up to date and syncing with P15">
                                                                                <CheckCircleOutlineIcon
                                                                                    className={
                                                                                        classes.complete
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title="Guest record has been modified by My B&R and is not syncing with P15">
                                                                                <HighlightOffIcon
                                                                                    className={
                                                                                        classes.incomplete
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                        {trip
                                                                            .is_p15_syncing
                                                                            .gear ===
                                                                        true ? (
                                                                            <Tooltip title="Gear selection is up to date and syncing with P15">
                                                                                <CheckCircleOutlineIcon
                                                                                    className={
                                                                                        classes.complete
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        ) : trip
                                                                              .is_p15_syncing
                                                                              .gear ===
                                                                          false ? (
                                                                            <Tooltip title="Gear selection has been modified by My B&R and is not syncing with P15">
                                                                                <HighlightOffIcon
                                                                                    className={
                                                                                        classes.incomplete
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <>

                                                                            </>
                                                                        )}
                                                                    </p>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <p>
                                                                        {
                                                                            trip.p15_tripname
                                                                        }
                                                                    </p>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <p>
                                                                        {
                                                                            trip.p15_startdate
                                                                        }{" "}
                                                                        -{" "}
                                                                        {
                                                                            trip.p15_enddate
                                                                        }
                                                                    </p>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setSelectedGuestId(
                                                                                trip.p15_guestsid
                                                                            );
                                                                            setSelectedDepartureId(
                                                                                trip.p15_tripdeparturesid
                                                                            );
                                                                            setModalHeading(
                                                                                `${activeContactName} - ${trip.p15_tripname} | ${trip.p15_startdate} - ${trip.p15_enddate}`
                                                                            );
                                                                            setIsModalOpen(
                                                                                true
                                                                            );
                                                                        }}
                                                                    >
                                                                        View TIF
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    );
                                                })}
                                            <Button
                                                style={{ marginTop: "2rem" }}
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedContactId(null);
                                                    setUpcomingTripsList(null);
                                                }}
                                            >
                                                Back
                                            </Button>
                                        </>
                                    ) : (
                                        <>Loading Trips...</>
                                    )}
                                </>
                            )
                        ) : (
                            <>Loading...</>
                        )}
                    </TabPanel>
                    <TabPanel tabValue={tabValue} index={1}>
                        <DataGrid
                            isLoading={isLoading}
                            selectedDepartureId={selectedDepartureId}
                            guestsList={guestsList}
                            departureList={departureList}
                            setSelectedGuestId={setSelectedGuestId}
                            setSelectedContactId={setSelectedContactId}
                            setModalHeading={setModalHeading}
                            setIsModalOpen={setIsModalOpen}
                            handleViewGuestsClick={handleViewGuestsClick}
                        />
                    </TabPanel>
                    {/* <TabPanel tabValue={tabValue} index={0}>
                        <TifViewerUpcomingDepartures
                            selectedDepartureId={selectedDepartureId}
                            guestsList={guestsList}
                            departureList={departureList}
                            setSelectedGuestId={setSelectedGuestId}
                            setSelectedContactId={setSelectedContactId}
                            setModalHeading={setModalHeading}
                            setIsModalOpen={setIsModalOpen}
                            handleViewGuestsClick={handleViewGuestsClick}
                        />
                    </TabPanel> */}
                </>
            </Paper>
            <IframeModal
                fullScreen={true}
                isOpen={isModalOpen}
                heading={modalHeading}
                handleClick={() => setIsModalOpen(!isModalOpen)}
            >
                <TifViewer
                    details={{
                        contactid: selectedContactId,
                        guestid: selectedGuestId,
                        departureid: selectedDepartureId,
                    }}
                />
            </IframeModal>
        </Container>
    );
};

export default TifSearch;
