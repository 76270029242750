import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

function CustomSelect(props) {

    const {
        options,
        inputLabelId,
        handleChange,
        labelName,
        value,
        selectId,
        selectLabelId,
        children,
        error,
    } = props

    const buildOptions = (option, index) => {
        if (option.value == "select" || option.value == "") {
            return (
                <MenuItem value={option.value} key={index} disabled>
                    {option.name}
                </MenuItem>
            )
        }

        return (
            <MenuItem value={option.value} key={index}>
                {option.name}
            </MenuItem>
        )
    }

    return (
        <React.Fragment>
            <InputLabel id={inputLabelId} error={error}>{labelName}{children}</InputLabel>
            <Select
                labelId={selectLabelId}
                id={selectId}
                value={value}
                onChange={handleChange}
            >
                {
                    options.map((option, index) => {
                        return buildOptions(option, index)
                    })
                }
            </Select>
        </React.Fragment>
    )
}

CustomSelect.propTypes = {
    options: PropTypes.any.isRequired,
    inputLabelId: PropTypes.any.isRequired,
    handleChange: PropTypes.any.isRequired,
    labelName: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    selectId: PropTypes.any.isRequired,
    selectLabelId: PropTypes.any.isRequired,
};

export default CustomSelect;
