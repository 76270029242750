import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Container,
    Grid,
    Button,
    Typography,
} from "@material-ui/core";
import DebouncedTextInput from "../components/_atoms/DebouncedTextInput";

const useStyles = makeStyles((theme) => ({
    tableCell: {
        display: "flex",
        alignItems: "center",
    },
}));

const CreditCardManagement = () => {
    const classes = useStyles();

    const [contact, setContact] = useState(null);
    const [linkGenerated, setLinkGenerated] = useState(false);
    const [generatedLink, setGeneratedLink] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);

    const fetchPaymentLink = async (contactid) => {
        const paymentLinkReq = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getPaymentLink/contact/${contactid}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        let paymentLink = await paymentLinkReq.json();
        setGeneratedLink(paymentLink.link);

        setLinkGenerated(true);
    };

    const copyToClipBoard = async () => {
        try {
            await navigator.clipboard.writeText(generatedLink);
            console.log("Content copied to clipboard");
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };

    const fetchContacts = async () => {
        if (searchTerm.trim() != "") {
            let contactReq = await fetch(
                `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/getContactByEmail/${searchTerm}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            let contactData = await contactReq.json();
            if (contactData.error) {
                setContact(null);
                return setErrorMessage(contactData.error.message);
            }
            setContact(contactData);
        }
    };

    useEffect(() => {
        fetchContacts();

        if (generatedLink.trim() != "") {
            setGeneratedLink("");
        }
        if (linkGenerated) {
            setLinkGenerated(false);
        }
    }, [searchTerm]);

    useEffect(() => {
        copyToClipBoard();
    }, [generatedLink]);

    const displayLinkTab = (contactid) => {
        if (!linkGenerated) {
            return (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => fetchPaymentLink(contactid)}
                >
                    Generate Credit Card Capture Link
                </Button>
            );
        }

        return (
            <Button
                variant="contained"
                size="small"
                onClick={() => copyToClipBoard()}
            >
                Copied to clipboard!
            </Button>
        );
    };

    const displayContent = () => {
        if (contact != null) {
            return (
                <>
                    <Grid
                        container
                        spacing={1}
                        style={{ margin: "1rem 0", fontWeight: "bold" }}
                    >
                        <Grid item xs={3} className={classes.tableCell}>
                            Name
                        </Grid>
                        <Grid item xs={4} className={classes.tableCell}>
                            Email
                        </Grid>
                        <Grid item xs={5} className={classes.tableCell}>
                            Payment Link
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={3} className={classes.tableCell}>
                            {contact.firstname} {contact.lastname}
                        </Grid>
                        <Grid item xs={4} className={classes.tableCell}>
                            {contact.emailaddress1}
                        </Grid>
                        <Grid item xs={5}>
                            {displayLinkTab(contact.contactid)}
                        </Grid>
                    </Grid>
                </>
            );
        } else {
            return (
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            <br></br>
                            <Typography>{errorMessage}</Typography>
                        </Grid>
                    </Grid>
                </>
            );
        }
    };

    return (
        <Container maxWidth="md">
            <DebouncedTextInput
                label={"Find a Traveller"}
                placeholder={"Search for a traveller by email..."}
                value={searchTerm}
                onDebouncedChange={setSearchTerm}
            />
            {displayContent()}
        </Container>
    );
};

export default CreditCardManagement;
