import React from "react";
import {
    makeStyles,
    Card,
    CardActions,
    CardContent,
    Typography,
    Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cardContent: {
        minHeight: 250,
    },
    cardTitle: {
        textTransform: "uppercase",
        fontWeight: "600",
        color: "#5f6368",
        fontSize: "12px",
    },
    cardValue: {
        fontWeight: "500",
        color: "rgba(0,0,0,0.87)",
        fontSize: "36px",
    },
    cardSubvalue: {
        fontWeight: "500",
        color: "rgba(0,0,0,0.87)",
        fontSize: "24px",
    },
}));

const DataOverviewCard = ({ data }) => {
    const classes = useStyles();

    if (!data)
        return (
            <Card variant="outlined" style={{ padding: "24px" }}>
                <Typography className={classes.cardTitle} variant="body1">
                    Error: please provide data object {"<3"}
                </Typography>
            </Card>
        );

    return (
        <Card variant="outlined">
            <CardContent className={classes.cardContent}>
                <Typography className={classes.cardTitle} variant="body1">
                    {data.mainTitle}
                </Typography>
                <Typography className={classes.cardValue} variant="body1">
                    {data.mainValue}
                </Typography>
                <Typography className={classes.cardTitle} variant="body1">
                    {data.secondaryTitle}
                </Typography>
                <Typography className={classes.cardSubvalue} variant="body1">
                    {data.secondaryValue}
                </Typography>
            </CardContent>
            {/* <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions> */}
        </Card>
    );
};

export default DataOverviewCard;
