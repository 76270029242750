import React from "react";

// MATERIAL UI
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';


function LoadingWheel(props) {

    return (
        <div>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={props.open}
                fullWidth={true}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{overflow: "hidden", height: 150}}
                >
                    <CircularProgress />
                </Grid>
            </Dialog>
        </div>
    )
}



export default LoadingWheel;
