import React, { useEffect, useReducer, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { HEADERS } from "../config";
import { ERROR_MESSAGE, ERROR_TITLE } from "../constants.js";

// Reducer
import { homeReducer } from "../reducers/HomeReducer";

// Material UI
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";

// Context
import { UserContext } from "../context/UserContext";
import { AlertContext } from "../context/AlertContext";

//SVG
import HandWaveIcon from "../assets/waving-hand.png";
import DataOverviewCard from "../components/_molecules/DataOverviewCard";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    handWaveIcon: {
        maxWidth: "5%",
        marginRight: "3%",
        marginBottom: "-1%",
    },
}));

function Home(props) {
    let { updateAlertTitle, updateIsAlertOpen, updateAlertMessage } =
        useContext(AlertContext);
    const { logOutUser } = useContext(UserContext);

    const classes = useStyles();
    const initialState = {
        firstName: localStorage.getItem("firstName"),
    };
    const [home, dispatchHome] = useReducer(homeReducer, initialState);

    let displayWelcomeMessage = () => {
        if (home.firstName == "") {
            return (
                <Typography variant="h4" component="h4" align="center">
                    <img
                        src={HandWaveIcon}
                        className={classes.handWaveIcon}
                        alt="handWaveIcon"
                    />
                    Welcome
                </Typography>
            );
        } else {
            return (
                <Typography variant="h4" component="h4" align="center">
                    <img
                        src={HandWaveIcon}
                        className={classes.handWaveIcon}
                        alt="handWaveIcon"
                    />
                    Welcome {home.firstName}
                </Typography>
            );
        }
    };

    return (
        <>
            <br></br>
            <br></br>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={11}>
                    {displayWelcomeMessage()}
                </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
                <Grid item xs={4}>
                    <DataOverviewCard
                        data={{
                            mainTitle: "2022 BOOKED GUESTS",
                            mainValue: 1135,
                            secondaryTitle: "THIS TIME LAST YEAR",
                            secondaryValue: 1084,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DataOverviewCard
                        data={{
                            mainTitle: "Travellers On The Road",
                            mainValue: 35,
                            secondaryTitle: "Travellers Departing Next Week",
                            secondaryValue: 22,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DataOverviewCard
                        data={{
                            mainTitle: "Traveller Booked In January",
                            mainValue: 235,
                            secondaryTitle: "Target",
                            secondaryValue: 245,
                        }}
                    />
                </Grid>
            </Grid> */}
            <br></br>
        </>
    );
}

export default Home;
