import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme =>({
    root: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogActions: {
        paddingLeft: 24,
        paddingRight: 24
    },
    muiDialogTitle: {
        paddingTop: 0,
        paddingBottom: 0
    }
}))

function Alert(props) {

    let classes = useStyles();
    let setStyles = () => {
        if (props.maxWidth == "xs") {
            return {textAlign: "center"}
        }
    };

    let buildButtons = () => {

        if (props.buttons.length > 1) {
            return props.buttons.map((button) => {
                if (button.icon != null) {
                    return (
                        <Grid item xs={12} sm={6} key={button.id}>
                            <div className={classes.root} key={button.id}>
                                <Button
                                    onClick={button.action}
                                    color={button.color}
                                    variant={button.variant}
                                    startIcon={<button.icon/>}
                                    fullWidth={true}
                                    >{button.name}
                                </Button>
                            </div>
                        </Grid>
                    );
                } else {
                    return (
                        <Grid item xs={12} sm={6} key={button.id}>
                            <div className={classes.root}>
                                <Button
                                    onClick={button.action}
                                    color={button.color}
                                    variant={button.variant}
                                    fullWidth={true}
                                    >{button.name}
                                </Button>
                            </div>
                        </Grid>
                    );
                }
            })
        } else if (props.buttons.length == 1) {
            let button = props.buttons[0];

            if (props.buttons[0].icon != null) {
                return (
                    <Grid item xs={12} key={0}>
                        <div className={classes.root}>
                            <Button
                                onClick={button.action}
                                color={button.color}
                                variant={button.variant}
                                startIcon={<button.icon/>}
                                fullWidth={true}
                            >{button.name}</Button>
                        </div>
                    </Grid>
                );
            } else {
                return (
                    <Grid item xs={12} key={button.id}>
                        <div className={classes.root} key={0}>
                            <Button
                                onClick={button.action}
                                color={button.color}
                                variant={button.variant}
                                fullWidth={true}
                            >{button.name}</Button>
                        </div>
                    </Grid>
                );
            };
        } else {
            return <Grid></Grid>
        };
    };

    let handleOnExit = () => {
        props.onClose(false);
        if (props.onExitButtonClick != null) {
            props.onExitButtonClick();
        };
    };


    return (
        <div>
            <Dialog
                open={props.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={props.maxWidth}
                fullWidth={true}
            >
                {
                    props.success
                    ?   <DialogTitle id="alert-dialog-title" style={{textAlign: "center"}}>
                            <DoneIcon style={{ color: green[500], fontSize: 50 }}/>
                        </DialogTitle>
                    :   <DialogTitle id="alert-dialog-title">
                            {props.title}
                        </DialogTitle>
                }

                <MuiDialogTitle disableTypography className={classes.muiDialogTitle}>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleOnExit}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={setStyles()}>
                        {props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Grid container>
                        {buildButtons()}
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}

Alert.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
    open: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.any.isRequired,
    maxWidth: PropTypes.string.isRequired
}


export default Alert;
