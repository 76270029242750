import React, { useState, useEffect, useReducer } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import DoneIcon from "@material-ui/icons/Done";
import FaceIcon from "@material-ui/icons/Face";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import HelpIcon from "@material-ui/icons/Help";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

import Button from "@material-ui/core/Button";

import IframeModal from "../presentational/IFrameModal";

import MaterialTable from "material-table";

//API Imports
import { api } from "../api/api";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    fieldName: {
        fontWeight: 700,
        margin: "10px 0",
    },
    previousInquiriesBodyText: {
        margin: 0,
    },
    mobileDivider: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    hideOnSmallViewPort: {
        [theme.breakpoints.down("sm")]: {
            display: "none !important",
        },
    },
    hideOnLargeViewPort: {
        [theme.breakpoints.up("md")]: {
            display: "none !important",
        },
    },
}));

const createData = (label, value) => {
    return { label, value };
};

let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const formatCurrency = (price) => dollarUS.format(price);

const Inquiry = (props) => {
    const classes = useStyles();
    let isDemo = false;
    if (props.location.search == "?environment=demo") {
        isDemo = true;
    }

    const inquiry_id = props.match.params.inquiryid;

    const [inquiry, setInquiry] = useState(null);
    const [inquiries, setInquiries] = useState(null);
    const [travelHistory, setTravelHistroy] = useState(null);
    const [evaluations, setEvaluation] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [currentP15Entity, setCurrentP15Entity] = useState(null);
    const [currentEntityType, setCurrentEntityType] = useState(null);

    //Define Data Refresh Function
    const refreshPageData = () => {
        api(
            isDemo
                ? "https://endpoint.scribesoft.com/v1/orgs/3487/requests/17737?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135"
                : "https://endpoint.scribesoft.com/v1/orgs/3487/requests/17905?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135",

            {
                method: "POST",
                body: {
                    inquiry_id: inquiry_id,
                },
            }
        ).then((inquiryData) => {
            setInquiry(inquiryData.inquiry[0]);
            setInquiries(
                inquiryData.inquiries.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.createdon) - new Date(a.createdon);
                })
            );
            api(
                isDemo
                    ? "https://endpoint.scribesoft.com/v1/orgs/3487/requests/17738?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135"
                    : "https://endpoint.scribesoft.com/v1/orgs/3487/requests/17906?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135",
                {
                    method: "POST",
                    body: {
                        contact_id: inquiryData.inquiry[0].contact_id,
                    },
                }
            ).then((travelHistoryData) => {
                if (!travelHistoryData.traveller_history)
                    return setTravelHistroy([]);
                setTravelHistroy(
                    travelHistoryData.traveller_history.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return (
                            new Date(b.departure_start_date) -
                            new Date(a.departure_start_date)
                        );
                    })
                );
            });
            api(
                isDemo
                    ? "https://endpoint.scribesoft.com/v1/orgs/3487/requests/17739?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135"
                    : "https://endpoint.scribesoft.com/v1/orgs/3487/requests/17907?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135",
                {
                    method: "POST",
                    body: {
                        contact_id: inquiryData.inquiry[0].contact_id,
                    },
                }
            ).then((evaluationsData) => {
                setEvaluation(evaluationsData);
            });
        });
    };

    useEffect(() => {
        refreshPageData();
    }, []);

    let leadOverviewRows;

    if (inquiry) {
        leadOverviewRows = [
            createData("Status Comment:", inquiry.inquiry_status_comments),
            createData("Traveller:", inquiry.inquiry_contact_name),
            createData("Preferred Name:", inquiry.contact_preferred_name),
            createData("Contact Type:", inquiry.contact_type),
            createData("Age:", inquiry.contact_age),
            createData("Inquiry Source:", inquiry.inquiry_source),
            createData("Timing", inquiry.inquiry_timing_description),
            createData(
                "When do you plan to travel:",
                inquiry.inquiry_when_do_you_plan_to_travel
            ),
            createData("Group Size:", inquiry.inquiry_group_size),
            createData(
                "Budget (per person/day):",
                inquiry.inquiry_budget_description
            ),
            createData("Email:", inquiry.contact_emailaddress1),
            createData("Telephone Number:", inquiry.contact_mobile_phone),
            createData("Completed Count:", inquiry.contact_completed_count),
            createData(
                "Last Evaluation Score:",
                inquiry.contact_last_evaluation_score
            ),
            createData(
                "Future Bookings Count:",
                inquiry.contact_future_bookings_count
            ),
            createData(
                "Active Inquiry Count:",
                inquiry.contact_active_inquiry_count
            ),
            createData(
                "Previous Inquiries:",
                inquiry.contact_last_inquiry_trip_names
            ),
            createData(
                "Last Inquiry Owner:",
                inquiry.contact_last_inquiry_owner
            ),
            createData("Last Inquiry Date:", inquiry.contact_last_inquiry_date),
            createData("Last Travel Agent:", inquiry.contact_last_travel_agent),
            createData("Persona:", ""),
            createData("Country of Residence:", inquiry.contact_country),
            createData("State of Residence:", inquiry.contact_state),
            createData("Zip/ Postal Code:", inquiry.contact_postal_code),
            createData("Total Invoiced:", inquiry.contact_total_invoiced),
            createData("Travellers' Notes:", inquiry.inquiry_contact_comments),
            createData("Inquiry GA Source:", inquiry.inquiry_ga_source),
        ];
    }

    const renderInquiryStatusChip = (status) => {
        switch (status) {
            case "New":
                return (
                    <Chip
                        label={status}
                        color="primary"
                        icon={<NewReleasesIcon />}
                    />
                );

            case "Contacted":
                return (
                    <Chip
                        label={status}
                        color="primary"
                        icon={<HeadsetMicIcon />}
                    />
                );

            case "Completed":
                return (
                    <Chip
                        label={status}
                        color="primary"
                        icon={<DoneAllIcon />}
                    />
                );
            case "Booked":
                return (
                    <Chip
                        label={status}
                        color="primary"
                        icon={<EventAvailableIcon />}
                    />
                );
            case "Reserved":
                return (
                    <Chip
                        label={status}
                        color="primary"
                        icon={<CardTravelIcon />}
                    />
                );
            case "Request Completed":
                return (
                    <Chip
                        label={status}
                        color="primary"
                        icon={<DoneAllIcon />}
                    />
                );

            default:
                return (
                    <Chip label={status} color="primary" icon={<HelpIcon />} />
                );
        }
    };

    const leadOverview = () => {
        return (
            <TableContainer component={Paper}>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: "250px" }}>
                                <img
                                    src="https://www.butterfield.com/wp-content/themes/butterfield/images/br-logo.svg"
                                    alt="logo"
                                />
                            </TableCell>
                            <TableCell align="left">
                                <div>
                                    <h2 style={{ marginBottom: 5 }}>
                                        Lead Overview for{" "}
                                        {inquiry.inquiry_contact_name}
                                    </h2>
                                    <h1
                                        style={{
                                            marginBottom: 15,
                                            marginTop: 0,
                                        }}
                                    >
                                        {inquiry.inquiry_name}
                                    </h1>
                                    <div
                                        style={{
                                            marginBottom: 5,
                                            marginTop: 0,
                                        }}
                                    >
                                        {renderInquiryStatusChip(
                                            inquiry.inquiry_status_name
                                        )}
                                    </div>

                                    <a
                                        target="_blank"
                                        href={
                                            isDemo
                                                ? "https://butterfielddemo.peak15systems.com/main.aspx?etc=10015&extraqs=formid%3d31c4a6d7-1dad-4c63-84bb-61f524cf156b&id=%7b" +
                                                  inquiry_id +
                                                  "%7d&pagetype=entityrecord"
                                                : "https://butterfield.peak15systems.com/main.aspx?etc=10015&extraqs=formid%3d31c4a6d7-1dad-4c63-84bb-61f524cf156b&id=%7b" +
                                                  inquiry_id +
                                                  "%7d&pagetype=entityrecord"
                                        }
                                        style={{ color: "inherit" }}
                                    >
                                        {" "}
                                        <p>Inquiry ID: {inquiry_id}</p>
                                    </a>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leadOverviewRows.map((row) => (
                            <TableRow key={row.label}>
                                <TableCell component="th" scope="row">
                                    <strong>{row.label}</strong>
                                </TableCell>
                                <TableCell align="left">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    //Material Table Lead Overview - NOT IN USE
    const muiTableLeadOverview = () => {
        return (
            <MaterialTable
                columns={[
                    {
                        title: "Field",
                        field: "label",
                        cellStyle: {
                            fontWeight: "600",
                        },
                    },
                    { title: "", field: "value" },
                ]}
                data={leadOverviewRows}
                title={
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div>
                                <h2
                                    style={{
                                        marginBottom: 0,
                                        marginTop: 0,
                                    }}
                                >
                                    Bookings
                                </h2>
                            </div>
                        </Grid>
                    </Grid>
                }
                options={{ paging: false, tableLayout: "fixed" }}
            />
        );
    };

    //Material Table Departure History
    const muiTableDepartureHistory = () => {
        return (
            <MaterialTable
                columns={[
                    {
                        title: "Departure",
                        field: "departure_name",
                        render: (rowData) => (
                            <div
                                style={{
                                    minWidth: "200px",
                                }}
                            >
                                {rowData.departure_name}
                            </div>
                        ),
                    },
                    {
                        title: "Departure Code",
                        field: "departure_code",
                    },
                    {
                        title: "Guest Status",
                        field: "guest_status_name",
                    },
                    {
                        title: "Booking Owner",
                        field: "booking_owner_name",
                    },
                    // {
                    //     title: "Tour Days",
                    //     field: "departure_tour_days",
                    // },
                    // {
                    //     title: "Departure From Price",
                    //     field: "departure_from_price",
                    //     render: (rowData) => (
                    //         <div
                    //             style={{
                    //                 minWidth: "120px",
                    //             }}
                    //         >
                    //             {rowData.departure_from_price}
                    //         </div>
                    //     ),
                    // },
                    {
                        title: "Booking Guide(s)",
                        field: "guides",
                        render: (rowData) => (
                            <div
                                style={{
                                    minWidth: "200px",
                                }}
                            >
                                {rowData.guides ? (
                                    <>
                                        {rowData.guides[0] ? (
                                            <>
                                                {rowData.guides[0].guide_name}
                                                <br></br>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        <br></br>
                                        {rowData.guides[1] ? (
                                            <>
                                                {rowData.guides[1].guide_name}
                                                <br></br>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {rowData.guides[2] ? (
                                            <>
                                                {rowData.guides[2].guide_name}
                                                <br></br>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        ),
                    },
                    // {
                    //     title: "",
                    //     field: "guests_id",
                    //     render: (rowData) => (
                    //         <div
                    //             style={{
                    //                 minWidth: "130px",
                    //             }}
                    //         >
                    //             <Button
                    //                 style={{
                    //                     textAlign: "center",
                    //                     color: "White !important",
                    //                 }}
                    //                 variant="contained"
                    //                 color="primary"
                    //                 onClick={() => {
                    //                     setCurrentP15Entity(rowData.guests_id);
                    //                     setCurrentEntityType("Guest Record");
                    //                     setIsModalOpen(!isModalOpen);
                    //                 }}
                    //             >
                    //                 View Guest Record
                    //             </Button>
                    //         </div>
                    //     ),
                    // },
                    // {
                    //     title: "",
                    //     field: "departure_id",
                    //     render: (rowData) => (
                    //         <Button
                    //             style={{ textAlign: "center" }}
                    //             variant="contained"
                    //             color="secondary"
                    //             onClick={() => {
                    //                 setCurrentP15Entity(rowData.departure_id);
                    //                 setCurrentEntityType("Departure Record");
                    //                 setIsModalOpen(!isModalOpen);
                    //             }}
                    //         >
                    //             View <br></br>Departure
                    //         </Button>
                    //     ),
                    // },
                    // {
                    //     title: "",
                    //     field: "evaluations",
                    //     render: (rowData) => (
                    //         <>
                    //             {rowData.evaluations ? (
                    //                 <Button
                    //                     style={{
                    //                         textAlign: "center",
                    //                         backgroundColor:
                    //                             "rgb(219, 170, 121)",
                    //                     }}
                    //                     variant="contained"
                    //                     color="secondary"
                    //                     // onClick={() => {
                    //                     //     setCurrentP15Entity(
                    //                     //         rowData.evaluations[0]
                    //                     //             .evaluation_id
                    //                     //     );
                    //                     //     setCurrentEntityType(
                    //                     //         "Evaluation Record"
                    //                     //     );
                    //                     //     setIsModalOpen(!isModalOpen);
                    //                     // }}
                    //                     href={
                    //                         "https://butterfield.peak15systems.com/main.aspx?etc=10047&extraqs=formid%3d56648917-962a-4e2c-a992-e1cc15d00188&id=%7b" +
                    //                         currentP15Entity +
                    //                         "%7d&pagetype=entityrecord"
                    //                     }
                    //                     target="_blank"
                    //                 >
                    //                     View <br></br>Evaluation
                    //                 </Button>
                    //             ) : (
                    //                 ""
                    //             )}
                    //         </>
                    //     ),
                    // },
                ]}
                data={travelHistory}
                title={
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div>
                                <h1
                                    style={{
                                        marginBottom: 0,
                                        marginTop: 0,
                                    }}
                                >
                                    Bookings for {inquiry.inquiry_contact_name}
                                </h1>
                            </div>
                        </Grid>
                    </Grid>
                }
                options={{ paging: false }}
            />
        );
    };

    const InquiryDetails = () => {
        let inquiryFields = [
            { field: "inquiry_status_comments", label: "Status Comment" },
            { field: "inquiry_source", label: "Inquiry Source" },
            {
                field: "inquiry_timing_description",
                label: "Planning to Travel",
            },
            {
                field: "inquiry_group_size",
                label: "Group Size (If Applicable)",
            },
            {
                field: "inquiry_budget_description",
                label: "Budget (per person/day)",
            },
            {
                field: "inquiry_contact_comments",
                label: "Additional Notes",
            },
        ];
        return (
            <Card style={{ marginBottom: "1rem" }}>
                <Container>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src="https://www.butterfield.com/wp-content/themes/butterfield/images/br-logo.svg"
                                alt="logo"
                                style={{ width: "80%", margin: "1rem auto" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <div>
                                <h2 style={{ marginBottom: 0 }}>
                                    Lead Overview for{" "}
                                    {inquiry.inquiry_contact_name}
                                </h2>
                                <h1 style={{ marginTop: 0, marginBottom: 5 }}>
                                    {inquiry.inquiry_name}
                                </h1>
                                <div>
                                    {renderInquiryStatusChip(
                                        inquiry.inquiry_status_name
                                    )}
                                </div>

                                <a
                                    target="_blank"
                                    href={
                                        isDemo
                                            ? "https://butterfielddemo.peak15systems.com/main.aspx?etc=10015&extraqs=formid%3d31c4a6d7-1dad-4c63-84bb-61f524cf156b&id=%7b" +
                                              inquiry_id +
                                              "%7d&pagetype=entityrecord"
                                            : "https://butterfield.peak15systems.com/main.aspx?etc=10015&extraqs=formid%3d31c4a6d7-1dad-4c63-84bb-61f524cf156b&id=%7b" +
                                              inquiry_id +
                                              "%7d&pagetype=entityrecord"
                                    }
                                    style={{ color: "inherit" }}
                                >
                                    <p>View Inquiry in Peak15</p>
                                </a>
                            </div>
                        </Grid>
                        <Divider style={{ width: "100%" }} />
                        {inquiryFields.map((field, i, arr) => {
                            return (
                                <>
                                    <Grid item xs={6} md={3}>
                                        <p className={classes.fieldName}>
                                            {field.label}:
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={9}>
                                        <p style={{ margin: "10px 0" }}>
                                            {field.field ==
                                            "inquiry_contact_comments"
                                                ? inquiry[field.field]
                                                    ? inquiry[field.field]
                                                          .split(";")
                                                          .map((comment) => (
                                                              <p>{comment}</p>
                                                          ))
                                                    : ""
                                                : inquiry[field.field]}
                                        </p>
                                    </Grid>

                                    {i == arr.length - 1 ? (
                                        <></>
                                    ) : (
                                        <Divider style={{ width: "100%" }} />
                                    )}
                                </>
                            );
                        })}
                    </Grid>
                </Container>
            </Card>
        );
    };
    const ContactOverview = () => {
        let contactFields = [
            { field: "contact_preferred_name", label: "Preferred Name" },
            { field: "contact_first_name", label: "First Name" },
            { field: "contact_last_name", label: "Last Name" },
            { field: "contact_type", label: "Traveller Type" },
            { field: "contact_age", label: "Age" },
            { field: "inquiry_owner_name", label: "Contact Owner" },
            { field: "contact_emailaddress1", label: "Email" },
            { field: "contact_total_invoiced", label: "Total Invoiced" },
            {
                field: "contact_active_inquiry_count",
                label: "Active Inquiries",
            },
            {
                field: "contact_last_inquiry_date",
                label: "Last Inquiry Date",
            },
            {
                field: "contact_completed_count",
                label: "Completed Bookings",
            },
            {
                field: "contact_future_bookings_count",
                label: "Future Bookings",
            },
        ];
        return (
            <Card style={{ marginBottom: "1rem" }}>
                <Container>
                    <Grid container>
                        <Grid item xs={12}>
                            <h1>About the Contact</h1>
                        </Grid>
                        {contactFields.map((field, i) => {
                            return (
                                <>
                                    <Grid item xs={6} md={3}>
                                        <p className={classes.fieldName}>
                                            {field.label}
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <p style={{ margin: "10px 0" }}>
                                            {field.field ===
                                            "contact_total_invoiced"
                                                ? formatCurrency(
                                                      inquiry[field.field]
                                                  )
                                                : inquiry[field.field]}
                                        </p>
                                    </Grid>
                                    {i % 2 !== 0 ? (
                                        <Divider style={{ width: "100%" }} />
                                    ) : (
                                        <Divider
                                            className={classes.mobileDivider}
                                        />
                                    )}
                                </>
                            );
                        })}
                        <ContactPreferences />
                        <PreviousInquiries />
                        <PreviousBookings />
                    </Grid>
                </Container>
            </Card>
        );
    };

    const ContactPreferences = () => {
        let contactFields = [
            { field: "contact_info_for_ta_tp", label: "Info for TA and TP" },
            { field: "contact_activity_level", label: "Activity Level" },
            {
                field: "contact_immediate_feedback",
                label: "Immediate Feedback",
            },
            { field: "contact_info_for_guides", label: "Info for Guides" },
            {
                field: "contact_preferred_amenities",
                label: "Preferred Amenities",
            },
            {
                field: "contact_additional_interests",
                label: "Additional Interests",
            },
            { field: "contact_food_drink_prefs", label: "Food/ Drink Prefs" },
            { field: "contact_long_options", label: "Long Options" },
            {
                field: "contact_traveller_type",
                label: "Traveller Type",
            },
            {
                field: "contact_individual_capability",
                label: "Individual Capability",
            },
            {
                field: "contact_active_traveller",
                label: "Active Traveller",
            },
            {
                field: "contact_other_trips",
                label: "Other Trips",
            },
            {
                field: "contact_importance_of_hotels",
                label: "Importance Of Hotels",
            },
            {
                field: "contact_importance_of_meals",
                label: "Importance Of Meals",
            },
            {
                field: "contact_importance_of_wine",
                label: "Importance Of Wine",
            },
            {
                field: "contact_room_bed_type",
                label: "Room Bed Type",
            },
        ];
        return (
            <Grid container>
                <Grid item xs={12}>
                    <h2>Additional Information</h2>
                </Grid>
                {contactFields.map((field, i) => {
                    return (
                        <>
                            <Grid item xs={6} md={3}>
                                <p className={classes.fieldName}>
                                    {field.label}
                                </p>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <p style={{ margin: "10px 0" }}>
                                    {inquiry[field.field]}
                                </p>
                            </Grid>
                            {i % 2 !== 0 ? (
                                <Divider style={{ width: "100%" }} />
                            ) : (
                                <Divider className={classes.mobileDivider} />
                            )}
                        </>
                    );
                })}
            </Grid>
        );
    };

    const PreviousInquiries = () => {
        const parseContactComments = (comment) => {
            let commentsArray = comment.split(";").map((comment) => comment);

            return commentsArray[7];
        };

        const generateRecentInquiries = (maxNumberOfItins) => {
            if (!inquiries) return <div>Loading inquiries...</div>;
            if (inquiries.length < 2) return <div>No previous inquiries</div>;

            let recentInquiries = [];

            let numberOfItins =
                inquiries.length > maxNumberOfItins
                    ? maxNumberOfItins + 1
                    : inquiries.length;

            console.log(numberOfItins);

            for (let i = 1; i < numberOfItins; i++) {
                recentInquiries.push(inquiries[i]);
            }

            console.log(recentInquiries);

            return recentInquiries.map((inquiry, i) => (
                <>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "1rem",
                        }}
                        className={classes.hideOnSmallViewPort}
                    >
                        {renderInquiryStatusChip(
                            inquiry.statuscode_displayname
                        )}
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <p
                            className={classes.fieldName}
                            style={{ marginBottom: 0 }}
                        >
                            <em>
                                {
                                    new Date(inquiry.createdon)
                                        .toISOString()
                                        .split("T")[0]
                                }
                            </em>
                        </p>
                        <h3
                            className={classes.fieldName}
                            style={{ marginTop: 0 }}
                        >
                            {inquiry.p15_name}
                        </h3>

                        <p className={classes.previousInquiriesBodyText}>
                            <strong>Status Comment: </strong>
                            {inquiry.p15_statuscomments
                                ? inquiry.p15_statuscomments
                                : "No status comment..."}
                        </p>

                        {/* <p className={classes.previousInquiriesBodyText}>
                            {inquiry.p15_comment
                                ? parseContactComments(inquiry.p15_comment)
                                : "No traveller comment..."}
                        </p> */}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "1rem",
                        }}
                        className={classes.hideOnLargeViewPort}
                    >
                        {renderInquiryStatusChip(
                            inquiry.statuscode_displayname
                        )}
                    </Grid>

                    <Divider
                        style={{
                            width: "100%",
                            marginTop: "1rem",
                        }}
                    />
                </>
            ));
        };

        return (
            <>
                <h2>Previous Inquiries</h2>
                <Grid container>{generateRecentInquiries(5)}</Grid>
            </>
        );
    };

    const PreviousBookings = () => {
        const BookingModal = ({ pastTrip }) => {
            let tpifFields = [
                { field: "tpif_submitted_by", label: "Submitted By" },
                { field: "tpif_submitted_on", label: "Submitted On" },
                { field: "tpif_accident_on_trip", label: "Accident on trip" },
                { field: "tpif_info_for_guides", label: "Info for guides" },
                { field: "tpif_active_traveler", label: "Active Traveller" },
                {
                    field: "tpif_additional_interests",
                    label: "Additional Interests",
                },
                {
                    field: "tpif_food_drinks_prefs",
                    label: "Foo and Drink Prefs",
                },
                { field: "tpif_gift_suggestion", label: "Gift Suggestion" },
                {
                    field: "tpif_immediate_feedback",
                    label: "Immediate Feedback",
                },
                {
                    field: "tpif_importance_of_hotels",
                    label: "Importance of Hotels",
                },
                {
                    field: "tpif_importance_of_meals",
                    label: "Importance of Meals",
                },
                {
                    field: "tpif_importance_of_wine",
                    label: "Importance of Wine",
                },
                {
                    field: "tpif_individual_capability",
                    label: "Individual Capability",
                },

                {
                    field: "tpif_info_for_ta_and_tp",
                    label: "Info for TA and TP",
                },
                { field: "tpif_long_options", label: "Long Options" },
                { field: "tpif_other_trips", label: "Other Trips" },
                {
                    field: "tpif_preferred_amenities",
                    label: "Preferred Amenities",
                },
                {
                    field: "tpif_room_bed_type_preference",
                    label: "Bed Preferences",
                },
                { field: "tpif_smart_phone", label: "Smart Phone" },
                { field: "tpif_traveller_type", label: "Traveller Type" },
            ];
            return (
                <>
                    {pastTrip.tpif_details ? (
                        <>
                            <Grid item xs={12}>
                                <h2>TPIF</h2>
                            </Grid>
                            {tpifFields.map((field, i) => (
                                <>
                                    <Grid item xs={6} md={2}>
                                        <p className={classes.fieldName}>
                                            <strong>{field.label}:</strong>
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <p>
                                            {
                                                pastTrip.tpif_details[0][
                                                    field.field
                                                ]
                                            }
                                        </p>
                                    </Grid>
                                    {i % 2 !== 0 ? (
                                        <Divider
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    ) : (
                                        <Divider
                                            className={classes.mobileDivider}
                                        />
                                    )}
                                </>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                    {pastTrip.other_booking_guests ? (
                        <>
                            <Grid item xs={12}>
                                <h2>Other Guests</h2>
                            </Grid>

                            {pastTrip.other_booking_guests.map((guest, i) => (
                                <>
                                    <Grid item xs={6} md={2}>
                                        <p className={classes.fieldName}>
                                            <strong>Guest {i + 1}</strong>
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <p>{guest.other_booking_guest_name}</p>
                                    </Grid>
                                    {i % 2 !== 0 ? (
                                        <Divider
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    ) : (
                                        <Divider
                                            className={classes.mobileDivider}
                                        />
                                    )}
                                </>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}

                    {pastTrip.guides ? (
                        <>
                            <Grid item xs={12}>
                                <h2>Guides</h2>
                            </Grid>
                            {pastTrip.guides.map((guide, i) => (
                                <>
                                    <Grid item xs={6} md={2}>
                                        <p className={classes.fieldName}>
                                            <strong>Guide {i + 1}</strong>
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <p>{guide.guide_name}</p>
                                    </Grid>
                                    {i % 2 !== 0 ? (
                                        <Divider
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    ) : (
                                        <Divider
                                            className={classes.mobileDivider}
                                        />
                                    )}
                                </>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                </>
            );
        };

        const generateBookings = () => {
            if (!travelHistory) return <div>Loading bookings...</div>;
            if (travelHistory.length == 0) return <div>No bookings</div>;
            return travelHistory.map((pastTrip, i) => (
                <>
                    <Grid
                        item
                        xs={2}
                        md={2}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        className={classes.hideOnSmallViewPort}
                    >
                        {renderInquiryStatusChip(
                            pastTrip.departure_status_name
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={9}
                        md={8}
                        style={{
                            padding: "0.5rem",
                        }}
                    >
                        <h3 className={classes.fieldName}>
                            {pastTrip.departure_name}
                        </h3>
                        <p className={classes.previousInquiriesBodyText}>
                            <strong>Booking Owner: </strong>
                            {pastTrip.booking_owner_name}
                        </p>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={3}
                        md={2}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                setIsModalOpen(!isModalOpen);
                                setModalHeading(
                                    `${inquiry.contact_preferred_name} ${inquiry.contact_last_name} - ${pastTrip.departure_name}`
                                );
                                setModalContent(
                                    <BookingModal pastTrip={pastTrip} />
                                );
                            }}
                        >
                            {"Details >"}
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        className={classes.hideOnLargeViewPort}
                    >
                        {renderInquiryStatusChip(
                            pastTrip.departure_status_name
                        )}
                    </Grid>

                    <Divider
                        style={{
                            width: "100%",
                            marginTop: "1rem",
                        }}
                    />
                </>
            ));
        };

        return (
            <>
                <h2>Bookings</h2>
                <Grid container>{generateBookings()}</Grid>
            </>
        );
    };

    return (
        <div style={{ padding: "85px 10px" }}>
            {inquiry ? (
                <>
                    <Container maxWidth="md">
                        <InquiryDetails />
                        <ContactOverview />
                        {/* {leadOverview()}
                        {travelHistory ? muiTableDepartureHistory() : ""} */}
                    </Container>

                    <IframeModal
                        fullScreen={true}
                        isOpen={isModalOpen}
                        heading={modalHeading}
                        handleClick={() => setIsModalOpen(!isModalOpen)}
                    >
                        <Container maxWidth="md" style={{ padding: "3rem 0" }}>
                            <Card style={{ marginBottom: "1rem" }}>
                                <Container>
                                    <Grid container>{modalContent} </Grid>
                                </Container>
                            </Card>
                        </Container>
                    </IframeModal>
                </>
            ) : (
                "Loading"
            )}
        </div>
    );
};

export default Inquiry;
