import React, { useState } from "react";
import { UserContext } from "../context/UserContext";

// Higher order component
import { withRouter } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import CopyrightIcon from "@material-ui/icons/Copyright";
import PaymentIcon from '@material-ui/icons/Payment';

// Icons
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/List";
import FeedbackIcon from "@material-ui/icons/Feedback";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import PersonIcon from "@material-ui/icons/Person";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";

// Assets
import "../assets/css/Navbar.css";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: "100%",
    },
    title: {
        flexGrow: 1,
    },
    fullList: {
        width: "auto",
    },
    list: {
        width: "100%",
    },
    link: {
        textDecoration: "none",
    },
    drawer: {
        textDecoration: "none",
        color: "black",
    },
    image: {
        maxWidth: "50%",
        height: "auto",
        margin: "auto",
    },
}));

function Navbar(props) {
    const [left, setLeft] = useState(false);
    const classes = useStyles();

    const toggleDrawer = (side, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setLeft(open);
    };

    const sideList = (side) => {
        return (
            <>
                <br></br>
                <img id="imageLogo" src={logo} className={classes.image} />
                <br></br>
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    style={{ height: "100%" }}
                >
                    <div
                        className={classes.list}
                        role="presentation"
                        onClick={toggleDrawer(side, !left)}
                        onKeyDown={toggleDrawer(side, !left)}
                    >
                        <List style={{ marginLeft: "8%" }}>
                            <Link to="/home" className={classes.drawer}>
                                <ListItem button key={"Home"}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Home"} />
                                </ListItem>
                            </Link>
                            {/* <Link to="/inquiries" className={classes.drawer}>
                                <ListItem button key={"Inquiries"}>
                                    <ListItemIcon>
                                        <FeedbackIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Inquiries"} />
                                </ListItem>
                            </Link> */}
                            <Link
                                to="/onlineTripPlanner"
                                className={classes.drawer}
                            >
                                <ListItem button key={"Online Trip Planner"}>
                                    <ListItemIcon>
                                        <CardTravelIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={"Online Trip Planner"}
                                    />
                                </ListItem>
                            </Link>
                            <Link to="/tifOverview" className={classes.drawer}>
                                <ListItem button key={"TIF Viewer"}>
                                    <ListItemIcon>
                                        <ContactMailIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"TIF Viewer"} />
                                </ListItem>
                            </Link>
                            {/* <Link to="/changeLog" className={classes.drawer}>
                                <ListItem button key={"Change Log"}>
                                    <ListItemIcon>
                                        <ListIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Change Log"} />
                                </ListItem>
                            </Link> */}
                            <Link
                                to="/travelerManagement"
                                className={classes.drawer}
                            >
                                <ListItem
                                    button
                                    key={"My B&R Account Management"}
                                >
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={"My B&R Account Management"}
                                    />
                                </ListItem>
                            </Link>

                            <Link
                                to="/creditCardManagement"
                                className={classes.drawer}
                            >
                                <ListItem
                                    button
                                    key={"Credit Card Management"}
                                >
                                    <ListItemIcon>
                                        <PaymentIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={"Generate Credit Card Capture Link"}
                                    />
                                </ListItem>
                            </Link>
                        </List>
                    </div>

                    <Typography variant="caption" style={{ marginBottom: 15 }}>
                        <CopyrightIcon
                            fontSize="small"
                            style={{ paddingTop: 7 }}
                        />{" "}
                        {new Date().getFullYear()} All Rights Reserved
                        <br></br>
                    </Typography>
                </Grid>
            </>
        );
    };

    const renderButton = (token, logOutUser) => {
        if (token) {
            return (
                <div>
                    <Tooltip title="Log Out" placement="bottom">
                        <Link
                            to="/"
                            className={classes.link}
                            onClick={() => logOutUser(props)}
                        >
                            <Button style={{ color: "white" }}>
                                <ExitToAppIcon style={{ marginLeft: "25px" }} />
                            </Button>
                        </Link>
                    </Tooltip>
                </div>
            );
        } else {
            return (
                <div>
                    <Link to="/" className={classes.link}>
                        <Button style={{ color: "white" }}>Login</Button>
                    </Link>
                </div>
            );
        }
    };

    const renderDrawer = () => {
        /*
        NOTE:
            Resizing the width of the drawer to be mobile and desktop friendly.
            This method is being used since we need to pass in a dynamic value
            for PaperProps.
        */

        if (window.screen.width < 600) {
            return (
                <Drawer
                    open={left}
                    onClose={toggleDrawer("left", !left)}
                    PaperProps={{ style: { width: "70%" } }}
                >
                    {sideList("left")}
                </Drawer>
            );
        } else if (window.screen.width >= 600 && window.screen.width < 960) {
            return (
                <Drawer
                    open={left}
                    onClose={toggleDrawer("left", !left)}
                    PaperProps={{ style: { width: "35%" } }}
                >
                    {sideList("left")}
                </Drawer>
            );
        } else {
            return (
                <Drawer
                    open={left}
                    onClose={toggleDrawer("left", !left)}
                    PaperProps={{ style: { width: "30%" } }}
                >
                    {sideList("left")}
                </Drawer>
            );
        }
    };

    const renderMenuButton = (token) => {
        if (token) {
            return (
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer("left", !left)}
                >
                    <MenuIcon />
                </IconButton>
            );
        } else {
            return (
                <Typography variant="h6" className={classes.title}>
                    Staff B&R
                </Typography>
            );
        }
    };

    return (
        /*
        NOTE:
            Consuming multiple context in the navbar component below
        */

        <div className={classes.root}>
            <UserContext.Consumer>
                {(userContext) => {
                    const { token, logOutUser } = userContext;
                    return (
                        <>
                            <AppBar position="fixed">
                                <Toolbar>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                    >
                                        {renderMenuButton(token)}
                                        {renderButton(token, logOutUser)}
                                    </Grid>
                                </Toolbar>
                            </AppBar>

                            {renderDrawer()}
                        </>
                    );
                }}
            </UserContext.Consumer>
        </div>
    );
}

export default withRouter(Navbar);
