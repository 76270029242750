export function loginReducer (state, action) {
    switch(action.type) {
        case "SET_EMAIL":
            return { ...state, email: action.payload}
        case "SET_PASSWORD":
            return { ...state, password: action.payload}
        case "SET_EMAIL_ERROR":
            return { ...state, emailError: action.payload}
        case "SET_PASSWORD_ERROR":
            return { ...state, passwordError: action.payload}
        case "SET_SHOW_EMAIL_ERROR":
            return { ...state, showEmailError: action.payload}
        case "SET_SHOW_PASSWORD_ERROR":
            return { ...state, showPasswordError: action.payload}
    }
}
