export function onlineTripPlannerReducer (state, action) {
    switch(action.type) {
        case "SET_HIDE_RESULTS":
            return { ...state, hideResults: action.payload}
        case "SET_SHOW_DELETE_CHECKBOX":
            return { ...state, toShowDelete: action.payload}
        case "SET_SEARCHED":
            return { ...state, searched: action.payload}
        case "SET_SELECTED_SEARCH_BY":
            return {
                ...state,
                selectedSearchBy: action.payload,
                tripId: null,
                tripName: "",
                departureId: null,
                departureName: "",
                guestId: null,
                guestName: "",
            }
        case "SET_SELECTED_IMPORT_BY":
            return {
                ...state,
                selectedImportBy: action.payload,
                importTripName: "",
                importTripId: null,
                importDepartureName: "",
                importDepartureId: null,
                importGuestName: "",
                importGuestId: null
            }
        case "SET_TRIPS":
            return { ...state, trips: action.payload}
        case "SET_DEPARTURES":
            return { ...state, departures: action.payload}
        case "SET_GUESTS":
            return { ...state, guests: action.payload}

        case "SET_IMPORT_BY_TRIPS":
            return {...state, importedTrips: action.payload}
        case "SET_IMPORT_BY_DEPARTURES":
            return {...state, importedDepartures: action.payload}
        case "SET_IMPORT_BY_GUESTS":
            return {...state, importedGuests: action.payload}
        case "SET_TRIP_NAME":
            return { ...state, tripName: action.payload, departureName: "", guestName: ""}
        case "SET_DEPARTURE_NAME":
            return { ...state, departureName: action.payload, tripName: "", guestName: ""}
        case "SET_GUEST_NAME":
            return { ...state, guestName: action.payload, tripName: "", departureName: ""}
        case "SET_OTP":
            return { ...state, otp: action.payload}
        case "SET_TRIP_ID":
            return { ...state, tripId: action.payload, departureId: null, guestId: null}
        case "SET_DEPARTURE_ID":
            return { ...state, departureId: action.payload, tripId: null, guestId: null}
        case "SET_GUEST_ID":
            return { ...state, guestId: action.payload, tripId: null, departureId: null}
        case "SET_BLOG":
            return {...state, blog: action.payload}
        case "SET_GETTING_IN_SHAPE":
            return { ...state, gettingInShape: action.payload}
        case "SET_GETTING_THERE":
            return {...state, gettingThere: action.payload}
        case "SET_PACKING_LIST":
            return {...state, packingList: action.payload}

        // import by
        case "SET_IMPORT_BY_GUEST_ID":
            return {...state, importGuestId: action.payload}
        case "SET_IMPORT_BY_GUEST_NAME":
            return {...state, importGuestName: action.payload}

        case "SET_IMPORT_BY_DEPARTURE_ID":
            return {...state, importDepartureId: action.payload}
        case "SET_IMPORT_BY_DEPARTURE_NAME":
            return {...state, importDepartureName: action.payload}

        case "SET_IMPORT_BY_TRIP_ID":
            return {...state, importTripId: action.payload}
        case "SET_IMPORT_BY_TRIP_NAME":
            return {...state, importTripName: action.payload}

        case "SET_BLOG_ID":
            return {...state, blogId: action.payload}
        case "SET_GETTING_IN_SHAPE_ID":
            return {...state, gettingInShapeId: action.payload}
        case "SET_GETTING_THERE_ID":
            return {...state, gettingThereId: action.payload}
        case "SET_PACKING_LIST_ID":
            return {...state, packingListId: action.payload}

        case "SET_TO_DELETE_BLOG":
            return {...state, toDeleteBlog: action.payload}
        case "SET_TO_DELETE_GETTING_IN_SHAPE":
            return {...state, toDeleteGettingInShape: action.payload}
        case "SET_TO_DELETE_GETTING_THERE":
            return {...state, toDeleteGettingThere: action.payload}
        case "SET_TO_DELETE_PACKING_LIST":
            return {...state, toDeletePackingList: action.payload}
        case "RESET_PAGE":
            return {
                ...state,
                tripId: null,
                departureId: null,
                guestId: null,
                importTripId: null,
                importDepartureId: null,
                importGuestId: null,
                tripName: "",
                departureName: "",
                guestName: "",
                importTripName: "",
                importDepartureName: "",
                importGuestName: "",
                selectedSearchBy: "select",
                selectedImportBy: "select",
                hideResults: true,
                searched: false,
                blog: "",
                gettingInShape: "",
                gettingThere: "",
                document: "",
                packingList: "",
                toShowDelete: false,
                toDeleteBlog: false,
                toDeleteGettingInShape: false,
                toDeleteGettingThere: false,
                toDeletePackingList: false,
                blogId: null,
                gettingThereId: null,
                packingListId: null,
                gettingInShapeId: null,

                blogHiddenInMyBnr: true,
                gettingInShapeHiddenInMyBnr: true,
                gettingThereHiddenInMyBnr: true,
                packingListHiddenInMyBnr: true
            }
        case "SET_RESET_SELECTED_IMPORT_BY":
            return {...state, selectedImportBy: action.payload}
        case "SET_BLOGS":
            return {...state, blogs: action.payload}
        case "SET_SHOW_PREVIEW_MODE":
            return {...state, toShowPreviewMode: action.payload}

        case "SET_BLOG_HIDDEN_IN_MY_BNR":
            return {...state, blogHiddenInMyBnr: action.payload}
        case "SET_GETTING_IN_SHAPE_HIDDEN_IN_MY_BNR":
            return {...state, gettingInShapeHiddenInMyBnr: action.payload}
        case "SET_GETTING_THERE_HIDDEN_IN_MY_BNR":
            return {...state, gettingThereHiddenInMyBnr: action.payload}
        case "SET_PACKING_LIST_HIDDEN_IN_MY_BNR":
            return {...state, packingListHiddenInMyBnr: action.payload}
    }
}
