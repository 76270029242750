// Convert String Value to Slug

// Input    = "Hello World"

// Output   = "hello-world"
export function convertStringToSlug(Text) {
    return Text.toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
}

// Get Unique Values In Array Based on Key

// Input
// arr = [
//     {
//         name: "John"
//     },
//     {
//         name: "Jane"
//     },
//     {
//         name: "Jane"
//     },
//     {
//         name: "Jim"
//     }
// ];
//
// comp = "name";

// Output
// unique = [
//     {
//         name: "John"
//     },
//     {
//         name: "Jane"
//     },
//     {
//         name: "Jim"
//     }
// ];
export function getUnique(arr, comp) {
    if (arr) {
        const unique = arr
            .map((e) => e[comp])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter((e) => arr[e])
            .map((e) => arr[e]);

        return unique;
    }

    return [];
}

export function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
}

export function numberWithCommas(num) {
    return num;
    // return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

//Generate UUID v4
export function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}

// Slugify a string
export function slugify(str) {
    if (str) {
        str = str.replace(/^\s+|\s+$/g, "");

        // Make the string lowercase
        str = str.toLowerCase();

        // Remove accents, swap ñ for n, etc
        var from =
            "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
        var to =
            "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
        }

        // Remove invalid chars
        str = str
            .replace(/[^a-z0-9 -]/g, "")
            // Collapse whitespace and replace by -
            .replace(/\s+/g, "-")
            // Collapse dashes
            .replace(/-+/g, "-");

        return str;
    } else {
        return "slugify-failed";
    }
}

//Convert number or string to Currency
export function toCurrency(value) {
    let number = parseFloat(value);

    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumSignificantDigits: Math.trunc(Math.abs(number)).toFixed().length,
    }).format(number);
}

//Filter via SubArray (e.g. Destination or Experience)
export function filterViaSubArray(arr, subArr, key, matchValue) {
    return arr.filter((obj) =>
        obj[subArr].some((subObj) => subObj[key] === matchValue)
    );
}

//Build list of unique destinations or experiences from trips array
export function buildUniqueAttributes(trips, attribute) {
    let uniqueAttributeList = [];

    trips.map((trip) => {
        if (trip[attribute].length > 0) {
            trip[attribute].map((attribute) => {
                uniqueAttributeList.push(attribute.p15_name);
            });
        }
    });

    uniqueAttributeList = uniqueAttributeList.filter(
        (v, i, a) => a.indexOf(v) === i
    );

    return uniqueAttributeList;
}

export function referenceStagingMedia(mediaUrl) {
    let isMediaUrl = mediaUrl
        ? mediaUrl.indexOf("https://media.butterfield.com") > -1
        : "";
    let returnUrl = mediaUrl;

    if (isMediaUrl) {
        let fileName = mediaUrl.match(/([^\/]*)\/*$/)[1];
        returnUrl = "https://staging.media.butterfield.com/" + fileName;
    }

    return returnUrl;
}

//Filter and Find Functions
export function where(collection, constraint) {
    return collection.filter((collectionItem) =>
        Object.keys(constraint).every(
            (key) =>
                collectionItem.hasOwnProperty(key) &&
                constraint[key] === collectionItem[key]
        )
    );
}

export function search(keyValue, key, array) {
    if (array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] === keyValue) {
                return array[i];
            }
        }
    }
}

export function parseWpCharacters(s) {
    let string = s;
    string = string.replace(/&#038;/g, "&");
    string = string.replace(/&#8217;/g, "'");
    string = string.replace(/&amp;/g, "&");
    return string;
}

export function parseInclusionList(inclusions) {
    let itemsArray = inclusions.split("* ");

    let trimmed = itemsArray.filter((item) => item.length > 0);

    return trimmed;
}

export function scrollToTarget(target, mobileWidth) {
    if (target) {
        const element = document.getElementById(target);
        if (element) {
            const headerOffset = mobileWidth ? 65 : 100;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    }
}

export function shimmerPlaceholder() {
    const toBase64 = (str) =>
        typeof window === "undefined"
            ? Buffer.from(str).toString("base64")
            : window.btoa(str);

    const shimmer = (w, h) => `
        <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient id="g">
                <stop stop-color="#333" offset="20%" />
                <stop stop-color="#222" offset="50%" />
                <stop stop-color="#333" offset="70%" />
                </linearGradient>
            </defs>
            <rect width="${w}" height="${h}" fill="#333" />
            <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
            <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
        </svg>
    `;

    return toBase64(shimmer(700, 475));
}

export function isMyBnRDateString(date) {
    if (!date) return false;
    if (typeof date != "string") return false;
    if (!date.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})\+(\d{2}:\d{2})$/g))
        return false;
    return true;
}
