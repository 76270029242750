import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { useDebounce } from "use-debounce";


export default function BlogAutoComplete(props) {

    let {blog, blogKey, entityTitle, handleOnBlogChange, handleDeleteBlogPost} = props

    const [blogs, setBlogs] = useState([]);

    const getInputValue = (blog) => {

        if (Object.keys(blog).length == 0) {
            return ""
        } else {
            return blog.title.rendered;
        }
    };

    const [input, setInput] = useState(getInputValue(blog));
    const [debouncedInputValue] = useDebounce(input, 500);

    const parseWpCharacters = (s) => {
        let string = s;
        string = string.replace(/&#038;/g, "&");
        string = string.replace(/&#8217;/g, "'");
        string = string.replace(/&amp;/g, "&");
        return string;
    }

    const refreshBlogList = () => {
        if (debouncedInputValue.length > 0) {
            axios.get(`https://blog.butterfield.com/wp-json/wp/v2/posts?_embed&per_page=15&search=${debouncedInputValue}`)
            .then((blogs) => setBlogs(blogs.data))
            .catch((err) => console.log("err: ", err));
        }
    };

    //Fetch Data
    useEffect(() => {
        setInput(debouncedInputValue)
        refreshBlogList()
    }, [debouncedInputValue]);

    useEffect(() => {
        // used to refresh the input field when user deletes the blog entity

        setInput(getInputValue(blog))
    }, [blog])

    useEffect(() => {
        /*
        NOTE:
            Used to delete a value from the blogs object when user tries to
            backspace to delete the input value
            In order to avoid errors we must ensure that we only run this function
            if the blog is not an empty object (e.g {})
        */

        if (Object.keys(blog).length != 0 && input == "") {
            handleDeleteBlogPost(blogKey)
        }
    }, [input])

    return (
        <div>
            <span>{entityTitle}</span>
            <Autocomplete
                onChange={(event, newValue) => {
                    if (newValue != null) {
                        handleOnBlogChange(blogKey, newValue)
                    }
                }}
                inputValue={input}
                onInputChange={(event, newInputValue) => setInput(newInputValue)}
                options={blogs}
                getOptionLabel={(blog) => parseWpCharacters(blog.title.rendered)}
                filterOptions={(blogs) => blogs}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{ width: "100%" }}
                        label="Search for a blog title..."
                        variant="outlined"
                    />
                )}
                closeIcon={
                    <>
                        <div
                            onClick={() => handleDeleteBlogPost(blogKey)}
                            aria-label="clear">
                            <ClearIcon fontSize="small" />
                        </div>
                    </>
                }
            />
        </div>
    );
}
