import React, { createContext } from 'react';

export const LoadingWheelContext = createContext();

class LoadingWheelProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
        }

        this.updateIsLoading = this.updateIsLoading.bind(this);
    }

    updateIsLoading (value) {
        this.setState({isLoading: value})
    }

    render () {
        return (
            <LoadingWheelContext.Provider
                value={{
                    ...this.state,
                    updateIsLoading: this.updateIsLoading,
                }}
            >
                {this.props.children}
            </LoadingWheelContext.Provider>
        );
    }
}

export default LoadingWheelProvider;
