exports.endPoints = () => {
    if (process.env.REACT_APP_P15_VERSION == "PROD") {
        return {
            setContactInfo: 20609,
            setTravelerProfile: 20639,
            // setTripGear: 20608,
            deleteTripGear: 21396,
            insertTripGear: 21397,
            setArrivalDeparture: 20640,
            setPaymentInsurance: 20606,
            setCommentsQuestions: 20610,
            populateGearTempTable: 21580,
        };
    } else {
        // Dev and Staging
        return {
            setContactInfo: 20558,
            setTravelerProfile: 20634,
            // setTripGear: 20557,
            deleteTripGear: 21459,
            insertTripGear: 21460,
            setArrivalDeparture: 20638,
            setPaymentInsurance: 20555,
            setCommentsQuestions: 20559,
            populateGearTempTable: 21579,
        };
    }
};
