import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TravellerManagementDialog({
    open,
    setOpen,
    dialogAction,
    userAccount,
    fetchUserAccounts,
}) {
    const activateUserAccount = async (userAccountId) => {
        let userAccountsReq = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/activateUserAccounts/${userAccountId}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        let userAccountsData = await userAccountsReq.json();

        if (userAccountsData.userAccount.success) {
            return fetchUserAccounts();
        }
        console.log(userAccountsData);
    };

    const deleteUserAccount = async (userAccountId) => {
        let userAccountsReq = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/deleteUserAccounts/${userAccountId}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        let userAccountsData = await userAccountsReq.json();

        if (userAccountsData.userAccount.success) {
            return fetchUserAccounts();
        }
        console.log(userAccountsData);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const returnDialogContent = (dialogAction) => {
        switch (dialogAction) {
            case "activate":
                return {
                    title: "Activate User Account?",
                    message: `Are you sure you want to activate the user account associated with ${userAccount?.emailaddress}?`,
                    buttonLabel: "Activate",
                    action: () => {
                        activateUserAccount(userAccount.id);
                        handleClose();
                    },
                };
            case "delete":
                return {
                    title: "Delete User Account?",
                    message: `Are you sure you want to delete the user account associated with ${userAccount?.emailaddress}?`,
                    buttonLabel: "Delete",
                    action: () => {
                        deleteUserAccount(userAccount.id);
                        handleClose();
                    },
                };

            default:
                return {
                    title: "Something went wrong.",
                };
        }
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {returnDialogContent(dialogAction).title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {returnDialogContent(dialogAction).message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={returnDialogContent(dialogAction).action}
                    color="secondary"
                    variant="contained"
                >
                    {returnDialogContent(dialogAction).buttonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
