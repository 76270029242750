import React, { useState, useEffect } from "react";

import {
    makeStyles,
    Container,
    Grid,
    Chip,
    IconButton,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import TravellerManagementDialog from "../components/_molecules/TravellerManagementDialog";
import DebouncedTextInput from "../components/_atoms/DebouncedTextInput";

const useStyles = makeStyles((theme) => ({
    tableCell: {
        display: "flex",
        alignItems: "center",
    },
}));

const TravelerManagement = () => {
    const classes = useStyles();

    const [userAccountsLoading, setUserAccountsLoading] = useState(false);
    const [userAccounts, setUserAccounts] = useState([]);
    const [filteredUserAccounts, setFilteredUserAccounts] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState("");
    const [selectedUserAccount, setSelectedUserAccount] = useState(null);

    const fetchUserAccounts = async () => {
        setUserAccountsLoading(true);

        let userAccountsReq = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}/v1/staff/getUserAccounts`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        let userAccountsData = await userAccountsReq.json();

        setUserAccounts(userAccountsData.userAccounts);
        setFilteredUserAccounts(userAccountsData.userAccounts);

        setUserAccountsLoading(false);
    };

    useEffect(() => {
        fetchUserAccounts();
    }, []);

    useEffect(() => {
        const filterUserAccounts = (searchTerm) => {
            let filteredUserAccounts = userAccounts;

            // Filtering process below
            if ((searchTerm.trim() || "") !== "") {
                filteredUserAccounts = filteredUserAccounts.filter(
                    (userAccount) => {
                        let shouldContactBeReturned;

                        let tagArray = [
                            userAccount.firstname,
                            userAccount.lastname,
                            userAccount.emailaddress,
                        ];

                        let tagCloud = tagArray.join(" ");

                        shouldContactBeReturned = tagCloud
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase());

                        return shouldContactBeReturned;
                    }
                );
                setFilteredUserAccounts(filteredUserAccounts);
            } else {
                setFilteredUserAccounts(userAccounts);
            }
        };

        filterUserAccounts(searchTerm);
    }, [searchTerm, userAccounts]);

    if (userAccountsLoading) return <>Loading...</>;

    return (
        <Container maxWidth="md">
            <TravellerManagementDialog
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                dialogAction={dialogAction}
                userAccount={selectedUserAccount}
                fetchUserAccounts={fetchUserAccounts}
            />
            <DebouncedTextInput
                label={"Find a Traveller"}
                placeholder={"Search for a traveller by name or email..."}
                value={searchTerm}
                onDebouncedChange={setSearchTerm}
            />
            <Grid
                container
                spacing={1}
                style={{ margin: "1rem 0", fontWeight: "bold" }}
            >
                <Grid item xs={1} className={classes.tableCell}>
                    User ID
                </Grid>
                <Grid item xs={3} className={classes.tableCell}>
                    Name
                </Grid>
                <Grid item xs={4} className={classes.tableCell}>
                    Email
                </Grid>
                <Grid item xs={4} className={classes.tableCell}>
                    Account Status
                    {/* <IconButton aria-label="select-account-status" size="small">
                        <ArrowDropDownIcon />
                    </IconButton> */}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                {filteredUserAccounts.map((userAccount) => (
                    <>
                        <Grid item xs={1} className={classes.tableCell}>
                            {userAccount.id}
                        </Grid>
                        <Grid item xs={3} className={classes.tableCell}>
                            {userAccount.firstname} {userAccount.lastname}
                        </Grid>
                        <Grid item xs={4} className={classes.tableCell}>
                            {userAccount.emailaddress}
                        </Grid>
                        <Grid item xs={3} className={classes.tableCell}>
                            {userAccount.isactive === 1 ? (
                                <Chip
                                    size="small"
                                    label="Active"
                                    style={{
                                        color: "#fff",
                                        width: "100%",
                                        backgroundColor: "#697a63",
                                    }}
                                />
                            ) : (
                                <Chip
                                    size="small"
                                    label="Inactive"
                                    clickable
                                    style={{
                                        color: "#fff",
                                        width: "100%",
                                        backgroundColor: "#894542",
                                    }}
                                    onClick={() => {
                                        setSelectedUserAccount(userAccount);
                                        setDialogAction("activate");
                                        setIsDialogOpen(true);
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                aria-label="delete-user-account"
                                style={{ padding: "5px" }}
                                onClick={() => {
                                    setSelectedUserAccount(userAccount);
                                    setDialogAction("delete");
                                    setIsDialogOpen(true);
                                }}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    </>
                ))}
            </Grid>
        </Container>
    );
};

export default TravelerManagement;
