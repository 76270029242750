import React, {useEffect, useContext} from 'react';
import { HEADERS } from "../config";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import platform from "platform";

function AutoRefresh() {

    let { updateUserInfo } = useContext(UserContext);

    const refreshToken = () => {
        if (localStorage.getItem("token")) {
            axios.post(process.env.REACT_APP_API_ENDPOINT + "/v1/staff/refreshToken", {
                firstName: localStorage.getItem("firstName"),
                lastName: localStorage.getItem("lastName"),
                platform: `${platform.name} v${platform.version}`
            }, HEADERS)
            .then((result) => {

                if (result.status == 222 && result.data.sessionTimeout) {
                    localStorage.clear();
                    updateUserInfo({token: ""});
                    window.location.reload();
                };

                if (result.data.token) {
                    updateUserInfo({token: result.data.token});
                    localStorage.setItem("token", result.data.token)
                    localStorage.setItem("firstName", result.data.firstName)
                    localStorage.setItem("lastName", result.data.lastName)
                    HEADERS["headers"]["Authorization"] = "Bearer " + result.data.token;
                }
            }).catch((err) => console.log(err));
        };
    };

    useEffect(() => {
        let timerSetInterval = setInterval(async() => await refreshToken(), 30000);
        return () => clearInterval(timerSetInterval);
    });

    return (<></>);
};

export default AutoRefresh;
