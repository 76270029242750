import React from "react";
import LoadingWheel from "../presentational/LoadingWheel";
import Alert from "../presentational/Alert";
import { LoadingWheelContext } from "../context/LoadingWheelContext";
import { AlertContext } from "../context/AlertContext";

function Popups() {
    return (
        <div>
            <LoadingWheelContext.Consumer>{
                (loadingWheelContext) => {
                    const { isLoading } = loadingWheelContext;
                    return <LoadingWheel open={isLoading}/>
            }}</LoadingWheelContext.Consumer>


            <AlertContext.Consumer>{
                (alertContext) => {
                    const {
                        isOpen, title, message, buttons, success, maxWidth,
                        updateIsAlertOpen, exitButtonCallback
                     } = alertContext;

                    return (
                        <Alert
                            open={isOpen}
                            title={title}
                            message={message}
                            buttons={buttons}
                            success={success}
                            maxWidth={maxWidth}
                            onClose={updateIsAlertOpen}
                            onExitButtonClick={exitButtonCallback}
                        />
                    )
            }}</AlertContext.Consumer>
        </div>
    )
}

export default Popups;
