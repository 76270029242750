import React from 'react';
import { Route, Redirect } from "react-router-dom";

export function PrivateRoute({ component: Component, ...rest }) {
    /*
    NOTE:
        Private routes should be available only to components that require the
        user to be logged in. Syntax for private routes are the same as
        <Route path="somePath" component={someComponent}/>
    */

    let canUserProceed = (props) => {
        if (
            localStorage.getItem("token")
            || (
                props.history.location.pathname === "/successSignup"
                || props.history.location.pathname === "/successForgotPassword"
            )
        ) {
            return <Component {...props} />
        } else {
            return <Redirect to="/" />
        }
    }

    return (
        <Route
            { ...rest }
            render={(props) => (
                canUserProceed(props)
            )}
        />
    )
}
