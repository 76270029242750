import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
// import DoneIcon from "@material-ui/icons/Done";
// import FaceIcon from "@material-ui/icons/Face";
import DoneAllIcon from "@material-ui/icons/DoneAll";
// import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
// import FiberNewIcon from "@material-ui/icons/FiberNew";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import HelpIcon from "@material-ui/icons/Help";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

const renderInquiryStatusChip = (status) => {
    switch (status) {
        case "New":
            return (
                <Chip
                    label={status}
                    color="primary"
                    icon={<NewReleasesIcon />}
                />
            );

        case "Contacted":
            return (
                <Chip
                    label={status}
                    color="primary"
                    icon={<HeadsetMicIcon />}
                />
            );

        case "Completed":
            return (
                <Chip label={status} color="primary" icon={<DoneAllIcon />} />
            );
        case "Booked":
            return (
                <Chip
                    label={status}
                    color="primary"
                    icon={<EventAvailableIcon />}
                />
            );
        case "Reserved":
            return (
                <Chip
                    label={status}
                    color="primary"
                    icon={<CardTravelIcon />}
                />
            );
        case "Request Completed":
            return (
                <Chip label={status} color="primary" icon={<DoneAllIcon />} />
            );

        default:
            return <Chip label={status} color="primary" icon={<HelpIcon />} />;
    }
};

const InquirySearch = () => {
    const [areInquiriesLoading, setAreInquiriesLoading] = useState(false);
    const [inquiries, setInquiries] = useState([]);

    const fetchInquiriesList = async () => {
        setAreInquiriesLoading(true);
        let requestObject = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        };

        let inquiriesReq = await fetch(
            `https://endpoint.scribesoft.com/v1/orgs/3487/requests/22149?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135`,
            requestObject
        );

        let inquiriesData = await inquiriesReq.json();

        setInquiries(inquiriesData.inquiries);
        console.log(inquiriesData.inquiries);
        setAreInquiriesLoading(false);
    };

    useEffect(() => {
        fetchInquiriesList();
    }, []);

    return (
        <Container maxWidth={"lg"}>
            <Paper style={{ padding: "2rem 3rem" }}>
                {areInquiriesLoading ? (
                    <p>Loading...</p>
                ) : (
                    inquiries
                        .sort(
                            (a, b) =>
                                new Date(b.createdon) - new Date(a.createdon)
                        )
                        .map((inquiry) => {
                            return (
                                <Grid container>
                                    <Grid item xs={3}>
                                        {renderInquiryStatusChip(
                                            inquiry.statuscode_displayname
                                        )}
                                    </Grid>
                                    <Grid item xs={7}>
                                        <p>{inquiry.p15_name}</p>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Link
                                            to={`/leadOverview/${inquiry.p15_inquiriesid}`}
                                        >
                                            <Button>View</Button>
                                        </Link>
                                    </Grid>
                                    <Divider />
                                </Grid>
                            );
                        })
                )}
                <Grid container>
                    <Grid item>
                        <p>Test</p>
                    </Grid>
                    <Grid item>
                        <Button>Test</Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default InquirySearch;
