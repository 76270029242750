import React, { useContext, useEffect, useReducer } from "react";
import SHA512 from "crypto-js/sha512";
import axios from "axios";
import { HEADERS } from "../config.js";
import { Link } from "react-router-dom";
import { ERROR_MESSAGE, ERROR_TITLE } from "../constants.js";

//MATERIAL UI
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";

// CONTEXT
import { UserContext } from "../context/UserContext";
import { LoadingWheelContext } from "../context/LoadingWheelContext";
import { AlertContext } from "../context/AlertContext";

// REDUCER
import { loginReducer } from "../reducers/LoginReducer.js";
import platform from "platform";

const useStyles = makeStyles((theme) => ({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    link: {
        textDecoration: "none",
        marginRight: theme.spacing(1),
        "&:visited": {
            color: "inherit",
        },
    },
    forgotPassword: {
        marginTop: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
    },
}));

function Login(props) {
    let initialState = {
        email: "",
        password: "",
        emailError: "",
        passwordError: "",
        showEmailError: false,
        showPasswordError: false,
    };

    let [loginInfo, dispatchLoginInfo] = useReducer(loginReducer, initialState);
    let { updateIsLoading } = useContext(LoadingWheelContext);
    let { updateUserInfo } = useContext(UserContext);
    let { updateAlertTitle, updateIsAlertOpen, updateAlertMessage } =
        useContext(AlertContext);
    let classes = useStyles();
    let { history } = props;

    let handleSubmit = () => {
        // If valid, proceed with setting values and calling API
        if (isUserInputValid()) {
            updateIsLoading(true);

            var userData = JSON.stringify({
                userEmail: loginInfo.email,
                userPassword: SHA512(loginInfo.password)
                    .toString()
                    .toUpperCase(),
                platform: `${platform.name} v${platform.version}`,
            });

            axios
                .post(
                    process.env.REACT_APP_API_ENDPOINT + "/v1/staff/login",
                    userData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                // fetch(`${process.env.REACT_APP_MY_API_ENDPOINT}/v1/login`, {
                //     method: "POST", // *GET, POST, PUT, DELETE, etc.
                //     // mode: 'cors', // no-cors, *cors, same-origin
                //     // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                //     //  credentials: 'same-origin', // include, *same-origin, omit
                //     headers: {
                //         "Content-Type": "application/json",
                //         // 'Content-Type': 'application/x-www-form-urlencoded',
                //     },
                //     //  redirect: 'follow', // manual, *follow, error
                //     //  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                //     body: userData, // body data type must match "Content-Type" header
                // })
                .then(async (result) => {
                    if (result.status !== 200) {
                        updateAlertTitle("Login Error");
                        updateIsLoading(false);
                        updateAlertMessage(result.data.error.message);
                        updateIsAlertOpen(true);
                    } else {
                        // console.log(result);
                        let sessionData = result.data;
                        // let sessionData = await result.json();
                        updateUserInfo({ token: sessionData.token });
                        localStorage.setItem("token", sessionData.token);
                        localStorage.setItem(
                            "firstName",
                            sessionData.firstname
                        );
                        localStorage.setItem("lastName", sessionData.lastname);
                        HEADERS["headers"]["Authorization"] =
                            "Bearer " + sessionData.token;
                        updateIsLoading(false);
                        history.push("/home");
                    }
                })
                .catch((err) => {
                    updateIsLoading(false);
                    updateAlertTitle(ERROR_TITLE);
                    updateAlertMessage(err.response.data.error.message);
                    updateIsAlertOpen(true);
                });
        }
    };

    let isUserInputValid = () => {
        var isValid = true;

        if (loginInfo.password.length < 8) {
            dispatchLoginInfo({
                type: "SET_SHOW_PASSWORD_ERROR",
                payload: true,
            });
            dispatchLoginInfo({
                type: "SET_PASSWORD_ERROR",
                payload: "Password must be at least 8 characters long",
            });
            isValid = false;
        } else {
            dispatchLoginInfo({
                type: "SET_SHOW_PASSWORD_ERROR",
                payload: false,
            });
            dispatchLoginInfo({ type: "SET_PASSWORD_ERROR", payload: "" });
        }

        var re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(loginInfo.email)) {
            dispatchLoginInfo({ type: "SET_SHOW_EMAIL_ERROR", payload: true });
            dispatchLoginInfo({
                type: "SET_EMAIL_ERROR",
                payload: "Invalid email address",
            });
            isValid = false;
        } else {
            dispatchLoginInfo({ type: "SET_SHOW_EMAIL_ERROR", payload: false });
            dispatchLoginInfo({ type: "SET_EMAIL_ERROR", payload: "" });
        }

        return isValid;
    };

    let onEnter = (event) => {
        /*
        NOTE:
            On key press if the user enters the "Enter" key then it should trigger
            handleSubmit function.
        */

        if (event.which === 13 || event.keyCode === 13) {
            return handleSubmit();
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token")) history.push("/home");
    }, []);

    return (
        <div>
            <CssBaseline>
                <Card className={classes.card}>
                    <CardContent>
                        <br></br>
                        <Typography variant="h5" component="h6">
                            Welcome to B&R Staff Portal
                        </Typography>
                        <br></br>
                        <Typography component="i">
                            Please sign in below to access staff tools.
                        </Typography>
                        <br></br>

                        {/*FORM*/}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth={true}>
                                    <TextField
                                        error={loginInfo.showEmailError}
                                        id="email"
                                        label="Email"
                                        helperText={loginInfo.emailError}
                                        className={classes.textField}
                                        margin="normal"
                                        type="email"
                                        onChange={(event) =>
                                            dispatchLoginInfo({
                                                type: "SET_EMAIL",
                                                payload: event.target.value,
                                            })
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth={true}>
                                    <TextField
                                        error={loginInfo.showPasswordError}
                                        id="password"
                                        label="Password"
                                        helperText={loginInfo.passwordError}
                                        className={classes.textField}
                                        margin="normal"
                                        type="password"
                                        onChange={(event) =>
                                            dispatchLoginInfo({
                                                type: "SET_PASSWORD",
                                                payload: event.target.value,
                                            })
                                        }
                                        onKeyPress={(event) => {
                                            onEnter(event);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>

                    <CardActions>
                        <Grid container justify="space-between">
                            <Hidden smUp>
                                <br></br>
                                <br></br>
                            </Hidden>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-end"
                                item
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    className={classes.link}
                                    color="primary"
                                >
                                    Sign In
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                    <br></br>
                </Card>
            </CssBaseline>
        </div>
    );
}

export default Login;
