export function changeLogReducer (state, action) {
    switch(action.type) {
        case "SET_TRAVEL_ADVISORS":
            return { ...state, travelAdvisors: action.payload }
        case "SET_SELECTED_TRAVEL_ADVISOR":
            return { ...state, selectedTravelAdvisor: action.payload }
        case "SET_START_DATE_FILTER":
            return { ...state, selectedStartDate: action.payload }
        case "SET_END_DATE_FILTER":
            return { ...state, selectedEndDate: action.payload }
        case "SET_FIELD_NAMES_FILTER":
            return { ...state, fieldNames: action.payload }
        case "SET_SELECTED_FIELD_NAME":
            return { ...state, selectedFieldName: action.payload }
        case "SET_CHANGE_LOG_RECORDS":
            return { ...state, changeLogRecords: action.payload }
        case "SET_SHOW_TABLE":
            return { ...state, showTable: action.payload }
    }
}
