import React, { useState } from "react";

import { Button, Divider, makeStyles } from "@material-ui/core";
import { Box, Grid } from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: "32px",
        fontWeight: 700,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        textTransform: "none",
    },
    subTitle: {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        textTransform: "none",
    },
    sectionTitle: {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        textTransform: "none",
        marginTop: 32,
    },
    fieldName: {
        fontWeight: 700,
        margin: "10px 0",
    },
    fullList: {
        width: "auto",
    },
    list: {
        width: "100%",
    },
    link: {
        textDecoration: "none",
    },
    drawer: {
        textDecoration: "none",
        color: "black",
    },
    image: {
        maxWidth: "90%",
        height: "auto",
        margin: "auto",
    },
    complete: {
        color: "green",
    },
    incomplete: {
        color: "red",
    },
    alert: {
        color: "#ff8d00",
    },
}));

const TifViewerUpcomingDepartures = ({
    selectedDepartureId,
    guestsList,
    departureList,
    setSelectedGuestId,
    setSelectedContactId,
    setModalHeading,
    setIsModalOpen,
    handleViewGuestsClick,
}) => {
    const classes = useStyles();

    const [departuresToDisplay, setDeparturesToDisplay] = useState(100);

    const handleLoadMoreClick = () => {
        setDeparturesToDisplay(departuresToDisplay + 100);
    };

    return (
        <>
            {selectedDepartureId ? (
                <>
                    <Grid container>
                        <Grid item xs={2}>
                            <p>
                                <strong>Section Statuses</strong>
                            </p>
                        </Grid>
                        <Grid item xs={3}>
                            <p>
                                <strong>Traveller Name</strong>
                            </p>
                        </Grid>
                        <Grid item xs={2}>
                            <p>
                                <strong>Traveller Email</strong>
                            </p>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                // flexDirection: "column",
                            }}
                        >
                            <p>
                                <strong>Records Syncing</strong>
                            </p>
                        </Grid>
                    </Grid>
                    {guestsList.map((guest) => {
                        return (
                            <>
                                <Box style={{ margin: "4px" }}>
                                    <Divider />
                                </Box>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            // justifyContent: "center",
                                            alignItems: "center",
                                            // flexDirection: "column",
                                        }}
                                    >
                                        <ReportProblemIcon
                                            className={classes.alert}
                                        />
                                        <CheckCircleIcon
                                            className={classes.complete}
                                        />
                                        <CheckCircleIcon
                                            className={classes.complete}
                                        />
                                        <ReportProblemIcon
                                            className={classes.alert}
                                        />
                                        <CheckCircleIcon
                                            className={classes.complete}
                                        />
                                        <CheckCircleIcon
                                            className={classes.complete}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <p>{guest.p15_name}</p>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <p>{guest.emailaddress1}</p>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={3}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            // flexDirection: "column",
                                        }}
                                    >
                                        <DoneAllIcon
                                            className={classes.complete}
                                        />
                                        <SyncProblemIcon
                                            className={classes.incomplete}
                                        />
                                        <SyncProblemIcon
                                            className={classes.incomplete}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setSelectedGuestId(
                                                    guest.p15_guestsid
                                                );
                                                setSelectedContactId(
                                                    guest.p15_contactguestsid
                                                );
                                                setModalHeading(
                                                    guest.p15_guestidname
                                                );

                                                setIsModalOpen(true);
                                            }}
                                            variant="contained"
                                            size="small"
                                        >
                                            View TIF
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        );
                    })}
                    <Box>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => handleViewGuestsClick(null)}
                        >
                            Back
                        </Button>
                    </Box>
                </>
            ) : (
                <>
                    <Grid container>
                        <Grid item xs={1}>
                            <p>
                                <strong></strong>
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>
                                <strong>Trip</strong>
                            </p>
                        </Grid>
                        <Grid item xs={2}>
                            <p>
                                <strong>Departure Code</strong>
                            </p>
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            // style={{
                            //     display: "flex",
                            //     justifyContent: "center",
                            // }}
                        >
                            <p>
                                <strong>Travellers</strong>
                            </p>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <p>
                                <strong>Records Syncing</strong>
                            </p>
                        </Grid>
                    </Grid>
                    {departureList
                        .sort(
                            (dep1, dep2) =>
                                new Date(dep1.p15_startdate) -
                                new Date(dep2.p15_startdate)
                        )
                        .map((departure, i) => {
                            if (i > departuresToDisplay) return <></>;
                            return (
                                <>
                                    <Box style={{ margin: "4px" }}>
                                        <Divider />
                                    </Box>
                                    <Grid
                                        container
                                        // onClick={() =>
                                        //     handleViewTripsClick(contact)
                                        // }
                                    >
                                        <Grid
                                            item
                                            xs={1}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                            }}
                                        >
                                            {i % 3 !== 0 && i % 4 !== 0 ? (
                                                <CheckCircleIcon
                                                    className={classes.complete}
                                                />
                                            ) : (
                                                <ReportProblemIcon
                                                    className={classes.alert}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <p>{departure.p15_tripname}</p>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <p>{departure.p15_departurecode}</p>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <p>
                                                {departure.p15_bookedguestcount}
                                            </p>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                            }}
                                        >
                                            {i % 5 !== 0 && i % 4 !== 0 ? (
                                                <DoneAllIcon
                                                    className={classes.complete}
                                                />
                                            ) : (
                                                <SyncProblemIcon
                                                    className={
                                                        classes.incomplete
                                                    }
                                                />
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleViewGuestsClick(
                                                        departure.p15_tripdeparturesid
                                                    )
                                                }
                                                variant="contained"
                                                size="small"
                                            >
                                                View Guests
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        })}
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            onClick={() => handleLoadMoreClick()}
                            variant="contained"
                            size="small"
                            style={{ width: "25%", margin: "auto" }}
                        >
                            Load More
                        </Button>
                    </Grid>
                </>
            )}
        </>
    );
};

export default TifViewerUpcomingDepartures;
