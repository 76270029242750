import React from "react";
import { Switch, Route } from "react-router-dom";
import "./assets/css/App.css";

// Private Route
import { PrivateRoute } from "./PrivateRoute";

// Material UI Theme
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

// Context Providers
import UserProvider from "./context/UserContext";
import LoadingWheelProvider from "./context/LoadingWheelContext";
import AlertProvider from "./context/AlertContext";

// Import components
import Home from "./containers/Home";
import Navbar from "./containers/Navbar";
import Login from "./containers/Login";
import Popups from "./presentational/Popups";
import Inquiry from "./containers/Inquiry";
import OnlineTripPlanner from "./containers/OnlineTripPlanner";
import TifSearch from "./containers/TifSearch";
import TifViewer from "./containers/TifViewer";
import ChangeLog from "./containers/ChangeLog";
import AutoRefresh from "./presentational/AutoRefresh";
import TravelerManagement from "./containers/TravelerManagement";
import CreditCardManagement from "./containers/CreditCardManagement";
import InquirySearch from "./containers/InquirySearch";

// Material UI components
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import axios from "axios";
axios.defaults.withCredentials = true;

const THEME = createMuiTheme({
    typography: {
        h1: {
            fontFamily: "Lora, serif",
            fontSize: "45px",
            color: "#494949",
            textTransform: "uppercase",
        },
        h2: {
            fontFamily: "Rock Salt, sans-serif",
            fontSize: "45px",
            color: "#194666",
        },
        h3: {
            fontFamily: "Lora, serif",
            fontSize: "35px",
            color: "#494949",
            textTransform: "uppercase",
        },
        h4: {
            fontFamily: "Lora, serif",
            fontSize: "30px",
            color: "#494949",
            textTransform: "uppercase",
        },
        h5: {
            fontFamily: "Rock Salt, sans-serif",
            fontSize: "20px",
            color: "#494949",
        },
        body1: {
            fontFamily: "Muli, sans-serif",
            color: "#494949",
            fontSize: "16px",
        },
    },
    palette: {
        primary: {
            main: "#3E739B",
        },
        secondary: {
            main: "#073B5C",
        },
    },
});

function App() {
    return (
        <div>
            <ThemeProvider theme={THEME}>
                <UserProvider>
                    <LoadingWheelProvider>
                        <AlertProvider>
                            <Navbar />
                            <Popups />
                            <br></br>
                            <br></br>

                            <AutoRefresh></AutoRefresh>
                            <CssBaseline>
                                <Container maxWidth="none">
                                    <br></br>
                                    <br></br>
                                    <Switch>
                                        <PrivateRoute
                                            path="/home"
                                            component={Home}
                                        />
                                        <PrivateRoute
                                            path="/inquiries"
                                            component={InquirySearch}
                                        />
                                        <PrivateRoute
                                            path="/onlineTripPlanner"
                                            component={OnlineTripPlanner}
                                        />
                                        <PrivateRoute
                                            path="/changelog"
                                            component={ChangeLog}
                                        />
                                        <Route
                                            path="/leadOverview/:inquiryid"
                                            component={Inquiry}
                                        />
                                        <PrivateRoute
                                            path="/tifOverview/contact/:contactid/guest/:guestid/departure/:departureid"
                                            component={TifViewer}
                                        />
                                        <PrivateRoute
                                            path="/tifOverview"
                                            component={TifSearch}
                                        />
                                        <PrivateRoute
                                            path="/travelerManagement"
                                            component={TravelerManagement}
                                        />
                                        <PrivateRoute
                                            path="/creditCardManagement"
                                            component={CreditCardManagement}
                                        />
                                        <Route
                                            exact
                                            path="/"
                                            component={Login}
                                        />
                                    </Switch>
                                </Container>
                            </CssBaseline>
                        </AlertProvider>
                    </LoadingWheelProvider>
                </UserProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
