import React, { useReducer, useContext, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { HEADERS } from "../config";
import { ERROR_MESSAGE, ERROR_TITLE } from "../constants.js";

// Material UI
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import Container from "@material-ui/core/Container";

// Custom components
import CustomSelect from "../presentational/CustomSelect";
import IframeModal from "../presentational/IFrameModal";
import BlogCarousel from "../presentational/BlogCarousel";
import BlogAutoComplete from "./BlogAutoComplete";

// Reducer
import { onlineTripPlannerReducer } from "../reducers/OnlineTripPlannerReducer";

// Context
import { LoadingWheelContext } from "../context/LoadingWheelContext";
import { AlertContext } from "../context/AlertContext";
import { UserContext } from "../context/UserContext";

// 3rd party modules
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles((theme) => ({
    accordingHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    root: {
        width: "100%",
    },
    cardRoot: {
        minWidth: 275,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    link: {
        textDecoration: "none",
        marginRight: theme.spacing(1),
        "&:visited": {
            color: "inherit",
        },
    },
    label: {
        marginRight: "2%",
        width: "115px",
        position: "absolute",
        bottom: 10,
    },
}));

//Helper Function - Filters unique object in an array base on an object property
function getUnique(arr, comp) {
    const unique = arr
        .map((e) => e[comp])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter((e) => arr[e])
        .map((e) => arr[e]);

    return unique;
}

function OnlineTripPlanner(props) {
    // Declaring constants
    const PUT = "PUT";
    const POST = "POST";
    const DELETE = "DELETE";
    const TRIP_ID = "tripId";
    const DEPARTURE_ID = "departureId";
    const GUEST_ID = "guestId";
    const SELECT = "select";
    const AUTOCOMPLETE_COMPONENT_INSTANCES = 5;

    const classes = useStyles();
    let initialState = {
        trips: [],
        departures: [],
        guests: [],
        importedTrips: [],
        importedDepartures: [],
        importedGuests: [],
        tripId: null,
        departureId: null,
        guestId: null,
        importTripId: null,
        importDepartureId: null,
        importGuestId: null,
        tripName: "",
        departureName: "",
        guestName: "",
        importTripName: "",
        importDepartureName: "",
        importGuestName: "",
        selectedSearchBy: SELECT,
        selectedImportBy: SELECT,
        hideResults: true,
        searched: false,
        blog: "",
        gettingThere: "",
        gettingInShape: "",
        packingList: "",
        toShowDelete: false,
        toDeleteBlog: false,
        toDeleteGettingInShape: false,
        toDeleteGettingThere: false,
        toDeletePackingList: false,
        blogId: null,
        gettingInShapeId: null,
        gettingThereId: null,
        packingListId: null,
        blogHiddenInMyBnr: true,
        gettingInShapeHiddenInMyBnr: true,
        gettingThereHiddenInMyBnr: true,
        packingListHiddenInMyBnr: true,

        /*
        NOTE:
            Creating this secondary blogs object will allows us to create the actual
            blog object that will send blog posts and save them in the DB.
            To prevent adding content to the DB that has no values for titles and
            IDs we would have to create a clean up function that removes the keys
            where their title and ID is an empty string.
        */
        blogs: {
            blog_1: {},
            blog_2: {},
            blog_3: {},
            blog_4: {},
            blog_5: {},
        },
        toShowPreviewMode: false,
    };

    const [otp, dispatchOnlineTripPlanner] = useReducer(
        onlineTripPlannerReducer,
        initialState
    );
    const { updateIsLoading } = useContext(LoadingWheelContext);
    const {
        updateAlertMessage,
        updateIsAlertOpen,
        updateAlertTitle,
        updateAlertButtons,
    } = useContext(AlertContext);
    const { logOutUser } = useContext(UserContext);

    const getRequests = (isSearchForImport = false) => {
        let requests = [];

        if (!isSearchForImport) {
            if (otp.selectedSearchBy == TRIP_ID) {
                requests = [
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/blog/trips/${otp.tripId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingThere/trips/${otp.tripId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/packingList/trips/${otp.tripId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingInShape/trips/${otp.tripId}`,
                        HEADERS
                    ),
                ];
            } else if (otp.selectedSearchBy == DEPARTURE_ID) {
                requests = [
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/blog/departures/${otp.departureId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingThere/departures/${otp.departureId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/packingList/departures/${otp.departureId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingInShape/departures/${otp.departureId}`,
                        HEADERS
                    ),
                ];
            } else {
                // guests
                requests = [
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/blog/guests/${otp.guestId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingThere/guests/${otp.guestId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/packingList/guests/${otp.guestId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingInShape/guests/${otp.guestId}`,
                        HEADERS
                    ),
                ];
            }
        } else {
            if (otp.selectedImportBy == TRIP_ID) {
                requests = [
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/blog/trips/${otp.importTripId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingThere/trips/${otp.importTripId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/packingList/trips/${otp.importTripId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingInShape/trips/${otp.importTripId}`,
                        HEADERS
                    ),
                ];
            } else if (otp.selectedImportBy == DEPARTURE_ID) {
                requests = [
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/blog/departures/${otp.importDepartureId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingThere/departures/${otp.importDepartureId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/packingList/departures/${otp.importDepartureId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingInShape/departures/${otp.importDepartureId}`,
                        HEADERS
                    ),
                ];
            } else {
                // guests
                requests = [
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/blog/guests/${otp.importGuestId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingThere/guests/${otp.importGuestId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/packingList/guests/${otp.importGuestId}`,
                        HEADERS
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/gettingInShape/guests/${otp.importGuestId}`,
                        HEADERS
                    ),
                ];
            }
        }

        return requests;
    };

    const handleSubmit = (isSearchForImport = false) => {
        let requests = isSearchForImport
            ? getRequests(isSearchForImport)
            : getRequests();

        // get the updated import records as well
        // requests.push(getImportByTrips(), getImportByDepartures(), getImportByGuests());

        // lock tripId field
        dispatchOnlineTripPlanner({ type: "SET_SEARCHED", payload: true });

        /* NOTE:
            Set the selectedImportBy to select so that in the setState function
            we are not setting the ID of the imported OTP but rather have the API
            create an ID after record creation .
        */
        if (otp.selectedImportBy != SELECT) {
            dispatchOnlineTripPlanner({
                type: "SET_RESET_SELECTED_IMPORT_BY",
                payload: SELECT,
            });
        }

        updateIsLoading(true);
        Promise.allSettled(requests).then((results) => {
            updateIsLoading(false);
            let newResults = results
                .filter((result) => result.status == "fulfilled")
                .map((newResult) => newResult.value);

            let hasServerError = false;
            let message;
            for (let result in results) {
                if (
                    results[result]["reason"] &&
                    results[result]["reason"]["request"]["status"] >= 500
                ) {
                    hasServerError = true;
                    message =
                        results[result]["reason"]["response"]["statusText"];
                    break;
                }
            }

            // dont render results if there's a server error
            if (hasServerError) {
                updateAlertTitle("Error");
                updateAlertMessage(message);
                updateAlertButtons([
                    {
                        id: 0,
                        name: "Ok",
                        color: "primary",
                        variant: "contained",
                        action: () => updateIsAlertOpen(false),
                    },
                ]);
                updateIsAlertOpen(true);
                return; // exit function
            }

            // show note to staff if no record has been found
            if (newResults.length == 0) {
                updateAlertTitle("Note");
                updateAlertMessage(
                    "No records found for the the " +
                        otp.selectedSearchBy +
                        ": " +
                        otp[otp.selectedSearchBy] +
                        ". Feel free to add new fields."
                );
                updateAlertButtons([
                    {
                        id: 0,
                        name: "Ok",
                        color: "primary",
                        variant: "contained",
                        action: () => updateIsAlertOpen(false),
                    },
                ]);
                updateIsAlertOpen(true);
            }

            // merge objects into one and set state
            let finalResults = mergedResults(newResults);
            setState(finalResults);

            updateIsLoading(true);
            Promise.all([
                getImportByTrips(),
                getImportByDepartures(),
                getImportByGuests(),
            ]).then((results) => {
                updateIsLoading(false);
                dispatchOnlineTripPlanner({
                    type: "SET_IMPORT_BY_TRIPS",
                    payload: results[0],
                });
                dispatchOnlineTripPlanner({
                    type: "SET_IMPORT_BY_DEPARTURES",
                    payload: results[1],
                });
                dispatchOnlineTripPlanner({
                    type: "SET_IMPORT_BY_GUESTS",
                    payload: results[2],
                });
            });
        });
    };

    const publish = () => {
        let otpArr = [];
        let url = `https://api.butterfield.com/v1/otp/publish`;
        let idTypeMap = {
            guestId: "guest_id",
            departureId: "departure_id",
            tripId: "trip_id",
        };
        let selectedIdType = idTypeMap[otp.selectedSearchBy];
        let selectedSearchById = otp.guestId || otp.departureId || otp.tripId;

        if ((otp.blog || "") != "") {
            otpArr.push({
                entity: "blog",
                id: selectedSearchById,
                idType: selectedIdType,
                content: otp.blog,
                hidden_in_mybnr: otp.blogHiddenInMyBnr,
            });
        }

        if ((otp.gettingThere || "") != "") {
            otpArr.push({
                entity: "gettingThere",
                id: selectedSearchById,
                idType: selectedIdType,
                content: otp.gettingThere,
                hidden_in_mybnr: otp.gettingThereHiddenInMyBnr,
            });
        }

        if ((otp.packingList || "") != "") {
            otpArr.push({
                entity: "packingList",
                id: selectedSearchById,
                idType: selectedIdType,
                content: otp.packingList,
                hidden_in_mybnr: otp.packingListHiddenInMyBnr,
            });
        }

        if ((otp.gettingInShape || "") != "") {
            otpArr.push({
                entity: "gettingInShape",
                id: selectedSearchById,
                idType: selectedIdType,
                content: otp.gettingInShape,
                hidden_in_mybnr: otp.gettingInShapeHiddenInMyBnr,
            });
        }

        updateIsLoading(true);
        axios
            .post(url, { otp: otpArr }, HEADERS)
            .then((results) => updateIsLoading(false))
            .catch((err) => {
                updateIsLoading(false);
                updateAlertTitle(ERROR_TITLE);
                updateAlertMessage(ERROR_MESSAGE);
                updateIsAlertOpen(true);
            });
    };

    const mergedResults = (newResults) => {
        let mergedResults = {};
        let final = {};
        for (let x in newResults) {
            mergedResults[x] = newResults[x];
            for (let y in newResults[x].data) {
                final[y] = newResults[x].data[y];
            }
        }
        return final;
    };

    const mergeBlogObjects = (apiData, clientData) => {
        let newObj = {};
        for (let key in clientData) {
            if (apiData[key] && apiData[key].title != "") {
                newObj[key] = apiData[key];
            } else {
                newObj[key] = { title: "", id: "" };
            }
        }

        return newObj;
    };

    const setState = (results) => {
        dispatchOnlineTripPlanner({
            type: "SET_SHOW_DELETE_CHECKBOX",
            payload: false,
        });
        resetDeleteCheckboxes();
        resetHideCheckboxes();

        // setting otp.blog object
        if (results.blog && "id" in results.blog) {
            dispatchOnlineTripPlanner({
                type: "SET_BLOG",
                payload: results.blog.content,
            });
            dispatchOnlineTripPlanner({
                type: "SET_BLOG_HIDDEN_IN_MY_BNR",
                payload: results.blog.hidden_in_mybnr,
            });
            if (otp.selectedImportBy == SELECT) {
                dispatchOnlineTripPlanner({
                    type: "SET_BLOG_ID",
                    payload: results.blog.id,
                });
            }

            // setting the blogs object
            let apiData = JSON.parse(results.blog.content);
            let clientData = { ...otp.blogs };
            let mergedObj = Object.assign({}, clientData, apiData);
            dispatchOnlineTripPlanner({
                type: "SET_BLOGS",
                payload: mergedObj,
            });
        } else {
            buildBlogsObject();
            dispatchOnlineTripPlanner({ type: "SET_BLOG_ID", payload: null });
            dispatchOnlineTripPlanner({ type: "SET_BLOG", payload: "" });
        }

        if (results.gettingThere && "id" in results.gettingThere) {
            dispatchOnlineTripPlanner({
                type: "SET_GETTING_THERE",
                payload: results.gettingThere.content,
            });
            dispatchOnlineTripPlanner({
                type: "SET_GETTING_THERE_HIDDEN_IN_MY_BNR",
                payload: results.gettingThere.hidden_in_mybnr,
            });
            if (otp.selectedImportBy == SELECT) {
                dispatchOnlineTripPlanner({
                    type: "SET_GETTING_THERE_ID",
                    payload: results.gettingThere.id,
                });
            }
        } else {
            dispatchOnlineTripPlanner({
                type: "SET_GETTING_THERE_ID",
                payload: null,
            });
            dispatchOnlineTripPlanner({
                type: "SET_GETTING_THERE",
                payload: "",
            });
        }

        if (results.packingList && "id" in results.packingList) {
            dispatchOnlineTripPlanner({
                type: "SET_PACKING_LIST",
                payload: results.packingList.content,
            });
            dispatchOnlineTripPlanner({
                type: "SET_PACKING_LIST_HIDDEN_IN_MY_BNR",
                payload: results.packingList.hidden_in_mybnr,
            });
            if (otp.selectedImportBy == SELECT) {
                dispatchOnlineTripPlanner({
                    type: "SET_PACKING_LIST_ID",
                    payload: results.packingList.id,
                });
            }
        } else {
            dispatchOnlineTripPlanner({
                type: "SET_PACKING_LIST_ID",
                payload: null,
            });
            dispatchOnlineTripPlanner({
                type: "SET_PACKING_LIST",
                payload: "",
            });
        }

        if (results.gettingInShape && "id" in results.gettingInShape) {
            dispatchOnlineTripPlanner({
                type: "SET_GETTING_IN_SHAPE",
                payload: results.gettingInShape.content,
            });
            dispatchOnlineTripPlanner({
                type: "SET_GETTING_IN_SHAPE_HIDDEN_IN_MY_BNR",
                payload: results.gettingInShape.hidden_in_mybnr,
            });
            if (otp.selectedImportBy == SELECT) {
                dispatchOnlineTripPlanner({
                    type: "SET_GETTING_IN_SHAPE_ID",
                    payload: results.gettingInShape.id,
                });
            }
        } else {
            dispatchOnlineTripPlanner({
                type: "SET_GETTING_IN_SHAPE_ID",
                payload: null,
            });
            dispatchOnlineTripPlanner({
                type: "SET_GETTING_IN_SHAPE",
                payload: "",
            });
        }

        dispatchOnlineTripPlanner({
            type: "SET_IMPORT_BY_TRIPS",
            payload: results.trips,
        });
        dispatchOnlineTripPlanner({
            type: "SET_IMPORT_BY_DEPARTURES",
            payload: results.departures,
        });
        dispatchOnlineTripPlanner({
            type: "SET_IMPORT_BY_GUESTS",
            payload: results.guests,
        });

        dispatchOnlineTripPlanner({ type: "SET_HIDE_RESULTS", payload: false });
    };

    const handleWysiwyg = (content, dispatcherType) => {
        if (content == "<p><br></p>") {
            dispatchOnlineTripPlanner({ type: dispatcherType, payload: "" });
        } else {
            dispatchOnlineTripPlanner({
                type: dispatcherType,
                payload: content,
            });
        }
    };

    const handleOnBlogChange = (blogKey, data) => {
        // create a clone object
        let newBlogs = { ...otp.blogs };
        newBlogs[blogKey] = data;

        // set the new state
        dispatchOnlineTripPlanner({ type: "SET_BLOGS", payload: newBlogs });

        let newObj = {};
        for (let blog in newBlogs) {
            if (Object.keys(newBlogs[blog]).length > 0) {
                newObj[blog] = newBlogs[blog];
            }
        }

        dispatchOnlineTripPlanner({
            type: "SET_BLOG",
            payload: JSON.stringify(newObj),
        });
    };

    const handleDeleteBlogPost = (blogKey) => {
        let newBlogs = { ...otp.blogs };
        let newOtpBlog = { ...JSON.parse(otp.blog) };
        newBlogs[blogKey] = {};
        delete newOtpBlog[blogKey];
        dispatchOnlineTripPlanner({ type: "SET_BLOGS", payload: newBlogs });
        dispatchOnlineTripPlanner({
            type: "SET_BLOG",
            payload: JSON.stringify(newOtpBlog),
        });
    };

    const handleSelectToDelete = () => {
        dispatchOnlineTripPlanner({
            type: "SET_SHOW_DELETE_CHECKBOX",
            payload: !otp.toShowDelete,
        });

        if (otp.toShowDelete) {
            resetDeleteCheckboxes();
        }
    };

    const resetDeleteCheckboxes = () => {
        let actions = [
            "SET_TO_DELETE_BLOG",
            "SET_TO_DELETE_GETTING_THERE",
            "SET_TO_DELETE_PACKING_LIST",
            "SET_TO_DELETE_GETTING_IN_SHAPE",
        ];

        for (let action in actions) {
            dispatchOnlineTripPlanner({
                type: actions[action],
                payload: false,
            });
        }
    };

    const resetHideCheckboxes = () => {
        let actions = [
            "SET_BLOG_HIDDEN_IN_MY_BNR",
            "SET_GETTING_IN_SHAPE_HIDDEN_IN_MY_BNR",
            "SET_GETTING_THERE_HIDDEN_IN_MY_BNR",
            "SET_PACKING_LIST_HIDDEN_IN_MY_BNR",
        ];

        for (let action in actions) {
            dispatchOnlineTripPlanner({ type: actions[action], payload: true });
        }
    };

    // displaying components
    const displayBlogInputFields = () => {
        let keys = Object.keys(otp.blogs);
        return (
            <>
                {keys.map((blogKey, idx) => {
                    let title = `Blog #${idx + 1}`;
                    return (
                        <div key={idx + 1}>
                            <BlogAutoComplete
                                blog={otp.blogs[blogKey]}
                                blogKey={blogKey}
                                entityTitle={title}
                                handleOnBlogChange={handleOnBlogChange}
                                handleDeleteBlogPost={handleDeleteBlogPost}
                            />
                            {idx != keys.length - 1 ? <br></br> : <></>}
                        </div>
                    );
                })}
            </>
        );
    };

    const displayBlogField = () => {
        return (
            <>
                <Grid item xs={4} sm={2} style={{ position: "relative" }}>
                    <label className={classes.label}>Blogs</label>
                </Grid>
                <Grid item xs={8} sm={10}>
                    {otp.toShowDelete && otp.blogId ? (
                        <FormControlLabel
                            control={
                                <>
                                    <Checkbox
                                        color="primary"
                                        checked={otp.toDeleteBlog}
                                        onChange={() => {
                                            dispatchOnlineTripPlanner({
                                                type: "SET_TO_DELETE_BLOG",
                                                payload: !otp.toDeleteBlog,
                                            });
                                        }}
                                    />
                                </>
                            }
                            label="Delete"
                        />
                    ) : (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={otp.blogHiddenInMyBnr}
                                    size={"small"}
                                    onChange={() =>
                                        dispatchOnlineTripPlanner({
                                            type: "SET_BLOG_HIDDEN_IN_MY_BNR",
                                            payload: !otp.blogHiddenInMyBnr,
                                        })
                                    }
                                    color="primary"
                                />
                            }
                            label="Hide"
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    {displayBlogInputFields()}
                </Grid>
            </>
        );
    };

    const displayGettingInShapeField = () => {
        return (
            <>
                <Grid item xs={4} sm={2} style={{ position: "relative" }}>
                    <label className={classes.label}>Getting In Shape</label>
                </Grid>
                <Grid item xs={8} sm={10}>
                    {otp.toShowDelete && otp.gettingInShapeId ? (
                        <FormControlLabel
                            control={
                                <>
                                    <Checkbox
                                        color="primary"
                                        checked={otp.toDeleteGettingInShape}
                                        onChange={() => {
                                            dispatchOnlineTripPlanner({
                                                type: "SET_TO_DELETE_GETTING_IN_SHAPE",
                                                payload:
                                                    !otp.toDeleteGettingInShape,
                                            });
                                        }}
                                    />
                                </>
                            }
                            label="Delete"
                        />
                    ) : (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={otp.gettingInShapeHiddenInMyBnr}
                                    size={"small"}
                                    onChange={() =>
                                        dispatchOnlineTripPlanner({
                                            type: "SET_GETTING_IN_SHAPE_HIDDEN_IN_MY_BNR",
                                            payload:
                                                !otp.gettingInShapeHiddenInMyBnr,
                                        })
                                    }
                                    color="primary"
                                />
                            }
                            label="Hide"
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <ReactQuill
                        style={{ width: "100%" }}
                        theme="snow"
                        value={otp.gettingInShape}
                        onChange={(content) =>
                            handleWysiwyg(content, "SET_GETTING_IN_SHAPE")
                        }
                    />
                </Grid>
            </>
        );
    };

    const displayGettingThereField = () => {
        return (
            <>
                <Grid item xs={4} sm={2} style={{ position: "relative" }}>
                    <label className={classes.label}>Getting There</label>
                </Grid>
                <Grid item xs={8} sm={10}>
                    {otp.toShowDelete && otp.gettingThereId ? (
                        <FormControlLabel
                            control={
                                <>
                                    <Checkbox
                                        color="primary"
                                        checked={otp.toDeleteGettingThere}
                                        onChange={() => {
                                            dispatchOnlineTripPlanner({
                                                type: "SET_TO_DELETE_GETTING_THERE",
                                                payload:
                                                    !otp.toDeleteGettingThere,
                                            });
                                        }}
                                    />
                                </>
                            }
                            label="Delete"
                        />
                    ) : (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={otp.gettingThereHiddenInMyBnr}
                                    size={"small"}
                                    onChange={() =>
                                        dispatchOnlineTripPlanner({
                                            type: "SET_GETTING_THERE_HIDDEN_IN_MY_BNR",
                                            payload:
                                                !otp.gettingThereHiddenInMyBnr,
                                        })
                                    }
                                    color="primary"
                                />
                            }
                            label="Hide"
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <ReactQuill
                        style={{ width: "100%" }}
                        theme="snow"
                        value={otp.gettingThere}
                        onChange={(content) =>
                            handleWysiwyg(content, "SET_GETTING_THERE")
                        }
                    />
                </Grid>
            </>
        );
    };

    const displayPackingListField = () => {
        return (
            <>
                <Grid item xs={4} sm={2} style={{ position: "relative" }}>
                    <label className={classes.label}>Packing List</label>
                </Grid>
                <Grid item xs={8} sm={10}>
                    {otp.toShowDelete && otp.packingListId ? (
                        <FormControlLabel
                            control={
                                <>
                                    <Checkbox
                                        color="primary"
                                        checked={otp.toDeletePackingList}
                                        onChange={() => {
                                            dispatchOnlineTripPlanner({
                                                type: "SET_TO_DELETE_PACKING_LIST",
                                                payload:
                                                    !otp.toDeletePackingList,
                                            });
                                        }}
                                    />
                                </>
                            }
                            label="Delete"
                        />
                    ) : (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={otp.packingListHiddenInMyBnr}
                                    size={"small"}
                                    onChange={() =>
                                        dispatchOnlineTripPlanner({
                                            type: "SET_PACKING_LIST_HIDDEN_IN_MY_BNR",
                                            payload:
                                                !otp.packingListHiddenInMyBnr,
                                        })
                                    }
                                    color="primary"
                                />
                            }
                            label="Hide"
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <ReactQuill
                        style={{ width: "100%" }}
                        theme="snow"
                        value={otp.packingList}
                        onChange={(content) =>
                            handleWysiwyg(content, "SET_PACKING_LIST")
                        }
                    />
                </Grid>
            </>
        );
    };

    const displayShowDeleteCheckbox = () => {
        if (
            otp.blogId ||
            otp.gettingThereId ||
            otp.gettingInShapeId ||
            otp.packingListId
        ) {
            return (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={otp.toShowDelete}
                                onChange={() => handleSelectToDelete()}
                                color="primary"
                            />
                        }
                        label="Select to delete"
                    />
                    <br></br>
                    <br></br>
                </>
            );
        }
    };

    const displayImport = () => {
        if (otp.searched) {
            return (
                <>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <CustomSelect
                                options={[
                                    { value: "select", name: "--Select--" },
                                    { value: "tripId", name: "Trip Name" },
                                    {
                                        value: "departureId",
                                        name: "Departure Name",
                                    },
                                    { value: "guestId", name: "Guest Name" },
                                ]}
                                inputLabelId="inputLabelImportBy"
                                handleChange={(event) => {
                                    dispatchOnlineTripPlanner({
                                        type: "SET_SELECTED_IMPORT_BY",
                                        payload: event.target.value,
                                    });
                                }}
                                labelName="Import By"
                                value={otp.selectedImportBy}
                                selectId="selectImportBy"
                                selectLabelId="selectLabelImportBy"
                            />
                        </FormControl>
                    </Grid>
                    <br></br>
                </>
            );
        }
    };

    const displayResults = () => {
        if (!otp.hideResults) {
            return (
                <Card className={classes.cardRoot}>
                    <CardContent>
                        <Grid container>
                            <Typography variant="h6" component="h6">
                                Results
                            </Typography>
                        </Grid>
                        <Divider />
                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {displayShowDeleteCheckbox()}
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth={true}>
                                    <Grid container>{displayBlogField()}</Grid>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth={true}>
                                    <Grid container>
                                        {displayGettingInShapeField()}
                                    </Grid>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth={true}>
                                    <Grid container>
                                        {displayGettingThereField()}
                                    </Grid>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth={true}>
                                    <Grid container>
                                        {displayPackingListField()}
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justify="space-between">
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-end"
                                item
                            >
                                {displayActionButtons()}
                            </Grid>
                        </Grid>
                    </CardActions>
                    <br></br>
                </Card>
            );
        }
    };

    const displayResetButton = () => {
        if (otp.searched) {
            return (
                <Button
                    variant="contained"
                    className={classes.link}
                    color="primary"
                    onClick={() =>
                        dispatchOnlineTripPlanner({ type: "RESET_PAGE" })
                    }
                >
                    Reset
                </Button>
            );
        }
    };

    const displayImportButton = () => {
        if (otp.selectedImportBy != SELECT) {
            return (
                <Button
                    disabled={
                        otp.importTripId == null &&
                        otp.importDepartureId == null &&
                        otp.importGuestId == null
                    }
                    variant="contained"
                    className={classes.link}
                    color="primary"
                    onClick={() => handleSubmit(true)}
                >
                    Import
                </Button>
            );
        }
    };

    const displayPreviewButton = () => {
        return (
            <Button
                variant="contained"
                className={classes.link}
                color="primary"
                onClick={() =>
                    dispatchOnlineTripPlanner({
                        type: "SET_SHOW_PREVIEW_MODE",
                        payload: !otp.toShowPreviewMode,
                    })
                }
            >
                Preview
            </Button>
        );
    };

    const displayActionButtons = () => {
        if (otp.toShowDelete) {
            return (
                <Button
                    disabled={
                        !(
                            otp.toDeleteBlog ||
                            otp.toDeleteGettingThere ||
                            otp.toDeleteGettingInShape ||
                            otp.toDeletePackingList
                        )
                    }
                    variant="contained"
                    className={classes.link}
                    color="primary"
                    onClick={handleDelete}
                >
                    Delete
                </Button>
            );
        } else {
            if (
                process.env.REACT_APP_API_ENDPOINT ==
                "https://staging.api.butterfield.com"
            ) {
                return (
                    <>
                        <Button
                            variant="outlined"
                            className={classes.link}
                            color="primary"
                            onClick={publish}
                        >
                            Publish
                        </Button>

                        {displayPreviewButton()}

                        <Button
                            variant="contained"
                            className={classes.link}
                            color="primary"
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </>
                );
            }

            return (
                <>
                    {displayPreviewButton()}

                    <Button
                        variant="contained"
                        className={classes.link}
                        color="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </>
            );
        }
    };

    // search by
    const displaySearchByTripId = () => {
        if (otp.selectedSearchBy == TRIP_ID) {
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <Autocomplete
                            disabled={otp.searched}
                            onChange={(event, newValue) =>
                                handleSelectTrip(newValue)
                            }
                            options={otp.trips}
                            getOptionLabel={(trip) =>
                                `${trip.p15_tripname} - ${trip.p15_tripcode}`
                            }
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Trip"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        }
    };

    const displaySearchByDepartureId = () => {
        if (otp.selectedSearchBy == DEPARTURE_ID) {
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <Autocomplete
                            disabled={otp.searched}
                            onChange={(event, newValue) =>
                                handleSelectDeparture(newValue)
                            }
                            options={otp.departures}
                            getOptionLabel={(departure) => departure.p15_name}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Departure"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        }
    };

    const displaySearchByGuestId = () => {
        if (otp.selectedSearchBy == GUEST_ID) {
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <Autocomplete
                            disabled={otp.searched}
                            onChange={(event, newValue) => {
                                handleSelectGuest(newValue);
                            }}
                            options={otp.guests}
                            getOptionLabel={(guest) =>
                                `${guest.guest_name} - ${
                                    guest.departure_code
                                } - ${guest.guest_id.toUpperCase()}`
                            }
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Guest"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        }
    };

    // import by
    const displayImportByTripId = () => {
        if (otp.selectedImportBy == TRIP_ID) {
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <Autocomplete
                            onChange={(event, newValue) =>
                                handleImportByTrip(newValue)
                            }
                            options={otp.importedTrips}
                            getOptionLabel={(trip) =>
                                `${trip.p15_tripname} - ${trip.p15_tripcode}`
                            }
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Trip"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        }
    };

    const displayImportByDepartureId = () => {
        if (otp.selectedImportBy == DEPARTURE_ID) {
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <Autocomplete
                            onChange={(event, newValue) =>
                                handleImportByDeparture(newValue)
                            }
                            options={otp.importedDepartures}
                            getOptionLabel={(departure) => departure.p15_name}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Departure"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        }
    };

    const displayImportByGuestId = () => {
        if (otp.selectedImportBy == GUEST_ID) {
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <Autocomplete
                            onChange={(event, newValue) =>
                                handleImportByGuest(newValue)
                            }
                            options={otp.importedGuests}
                            getOptionLabel={(guest) =>
                                `${guest.guest_name} - ${guest.departure_code}`
                            }
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Guest"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        }
    };

    // preview mode
    const displayAccordions = (heading, headingObj, index) => {
        if (heading == "blog") {
            if (otp.blog != "{}" && !headingObj.toHide) {
                return (
                    <div key={index}>
                        <div className={classes.root}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        className={classes.accordingHeading}
                                    >
                                        {headingObj.heading}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <BlogCarousel
                                        blogs={buildBlogsArray(otp.blog)}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <br></br>
                    </div>
                );
            }
        } else if (!headingObj.toHide) {
            return (
                <div key={index}>
                    <div className={classes.root}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography
                                    className={classes.accordingHeading}
                                >
                                    {headingObj.heading}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: otp[heading],
                                    }}
                                ></Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <br></br>
                </div>
            );
        }
    };

    const displayOtp = () => {
        const headings = {
            blog: {
                heading: "From Our Blog",
                toHide: otp["blogHiddenInMyBnr"],
            },
            packingList: {
                heading: "What to Pack",
                toHide: otp["packingListHiddenInMyBnr"],
            },
            gettingThere: {
                heading: "How To Get There",
                toHide: otp["gettingThereHiddenInMyBnr"],
            },
            gettingInShape: {
                heading: "Getting In Shape",
                toHide: otp["gettingInShapeHiddenInMyBnr"],
            },
        };

        return (
            <>
                <Container maxWidth="md">
                    <br></br>
                    <div style={{ maxWidth: "920px", margin: "0 auto" }}>
                        <h3
                            className={classes.title}
                            style={{
                                color: "#494949",
                                maxWidth: "500px",
                                margin: "0 auto",
                                fontWeight: 300,
                                borderBottom: "1px solid #494949",
                                paddingBottom: "5px",
                                fontFamily: "Lora, serif",
                                fontSize: "2em",
                                textAlign: "center",
                            }}
                        >
                            Know Before You Go
                        </h3>
                    </div>
                    <br></br>
                    {Object.keys(headings).map((heading, index) =>
                        displayAccordions(heading, headings[heading], index)
                    )}
                </Container>
            </>
        );
    };

    // APIs
    const getApiData = () => {
        let applicableFields = [];
        let fields = ["blog", "gettingThere", "packingList", "gettingInShape"];
        let otpMap = {
            blog: {
                plural: "blogs",
                id: "blogId",
                hidden_in_mybnr: "blogHiddenInMyBnr",
            },
            gettingThere: {
                plural: "gettingTheres",
                id: "gettingThereId",
                hidden_in_mybnr: "gettingThereHiddenInMyBnr",
            },
            packingList: {
                plural: "packingLists",
                id: "packingListId",
                hidden_in_mybnr: "packingListHiddenInMyBnr",
            },
            gettingInShape: {
                plural: "gettingInShapes",
                id: "gettingInShapeId",
                hidden_in_mybnr: "gettingInShapeHiddenInMyBnr",
            },
        };

        let column = null;
        let value = null;
        if (otp.selectedSearchBy == TRIP_ID) {
            column = "trip_id";
            value = otp.tripId;
        } else if (otp.selectedSearchBy == DEPARTURE_ID) {
            column = "departure_id";
            value = otp.departureId;
        } else if (otp.selectedSearchBy == GUEST_ID) {
            column = "guest_id";
            value = otp.guestId;
        }

        for (let field of fields) {
            let singleResourceUrl = `${
                process.env.REACT_APP_API_ENDPOINT
            }/v1/otp/${otpMap[field].plural}/${otp[otpMap[field].id]}`;

            // Handle edge case: Blog
            if (field == "blog" && otp.blog == "{}") {
                applicableFields.push({
                    method: DELETE,
                    url: singleResourceUrl,
                });
            } else if (otp[field].trim().length > 0) {
                let obj = {
                    content: otp[field],
                    hidden_in_mybnr: otp[otpMap[field].hidden_in_mybnr],
                    url: singleResourceUrl,
                    method: otp[otpMap[field].id] ? PUT : POST,
                };

                if (obj["method"] == POST) {
                    delete obj.content;
                    obj[
                        "url"
                    ] = `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/${otpMap[field].plural}`;
                    obj["data"] = {
                        content: otp[field],
                        hidden_in_mybnr: otp[otpMap[field].hidden_in_mybnr],
                    };
                    obj["data"][column] = value;
                }

                applicableFields.push(obj);
            }
        }

        return applicableFields;
    };

    const getTrips = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.REACT_APP_API_ENDPOINT}/v1/cachedData/allTrips`,
                    {},
                    HEADERS
                )
                .then((results) =>
                    resolve(getUnique(results.data, "p15_tripsid"))
                )
                .catch((err) => reject(err));
        });
    };

    const getDepartures = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.REACT_APP_API_ENDPOINT}/v1/cachedData/allDepartures`,
                    {},
                    HEADERS
                )
                .then((results) => resolve(results.data))
                .catch((err) => reject(err));
        });
    };

    const getGuests = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.REACT_APP_API_ENDPOINT}/v1/guests`,
                    {},
                    HEADERS
                )
                .then((results) => resolve(results.data.guests))
                .catch((err) => reject(err));
        });
    };

    const getImportByTrips = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/trips`,
                    {},
                    HEADERS
                )
                .then((results) => resolve(results.data.trips))
                .catch((err) => reject(err));
        });
    };

    const getImportByDepartures = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/departures`,
                    {},
                    HEADERS
                )
                .then((results) => resolve(results.data.departures))
                .catch((err) => reject(err));
        });
    };

    const getImportByGuests = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/guests`,
                    {},
                    HEADERS
                )
                .then((results) => resolve(results.data.guests))
                .catch((err) => reject(err));
        });
    };

    const handleSave = () => {
        /*
        NOTE:
            This function will either update or save a record depending on
            whether that entity has an ID or not.
        */

        let axiosRequests = getApiData();
        if (axiosRequests.length > 0) {
            let promises = [];
            for (let field in axiosRequests) {
                if (axiosRequests[field].method == POST) {
                    promises.push(
                        axios.post(
                            axiosRequests[field].url,
                            axiosRequests[field].data,
                            HEADERS
                        )
                    );
                } else if (axiosRequests[field].method == PUT) {
                    promises.push(
                        axios.put(
                            axiosRequests[field].url,
                            {
                                content: axiosRequests[field].content,
                                hidden_in_mybnr:
                                    axiosRequests[field].hidden_in_mybnr,
                            },
                            HEADERS
                        )
                    );
                } else if (axiosRequests[field].method == DELETE) {
                    promises.push(
                        axios.delete(axiosRequests[field].url, HEADERS)
                    );
                }
            }

            updateIsLoading(true);
            Promise.all(promises)
                .then((results) => {
                    updateIsLoading(false);
                    handleSubmit();
                })
                .catch((err) => {
                    updateIsLoading(false);
                    console.log(err);
                });
        } else {
            return;
        }
    };

    const handleDelete = () => {
        // get the records to be delete and push axios request to promise
        let promises = [];
        let obj = {
            toDeleteBlog: { plural: "blogs", id: otp.blogId },
            toDeleteGettingThere: {
                plural: "gettingTheres",
                id: otp.gettingThereId,
            },
            toDeletePackingList: {
                plural: "packingLists",
                id: otp.packingListId,
            },
            toDeleteGettingInShape: {
                plural: "gettingInShapes",
                id: otp.gettingInShapeId,
            },
        };

        for (let key in obj) {
            // ignore IDs that are null to avoid 500 server errors
            if (otp[key] && obj[key]["id"]) {
                let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/${obj[key]["plural"]}/${obj[key]["id"]}`;
                promises.push(axios.delete(url, HEADERS));
            }
        }

        updateIsLoading(true);
        Promise.allSettled(promises).then((results) => {
            updateIsLoading(false);
            handleSubmit();
        });
    };

    // search by
    const handleSelectTrip = (newValue) => {
        if (newValue) {
            dispatchOnlineTripPlanner({
                type: "SET_TRIP_ID",
                payload: newValue.p15_tripsid,
            });
            dispatchOnlineTripPlanner({
                type: "SET_TRIP_NAME",
                payload: newValue.p15_tripname,
            });
        } else {
            dispatchOnlineTripPlanner({
                type: "SET_TRIP_ID",
                payload: null,
            });
            dispatchOnlineTripPlanner({
                type: "SET_TRIP_NAME",
                payload: "",
            });
        }
    };

    const handleSelectDeparture = (newValue) => {
        if (newValue) {
            dispatchOnlineTripPlanner({
                type: "SET_DEPARTURE_ID",
                payload: newValue.p15_tripdeparturesid,
            });
            dispatchOnlineTripPlanner({
                type: "SET_DEPARTURE_NAME",
                payload: newValue.p15_name,
            });
        } else {
            dispatchOnlineTripPlanner({
                type: "SET_DEPARTURE_ID",
                payload: null,
            });
            dispatchOnlineTripPlanner({
                type: "SET_DEPARTURE_NAME",
                payload: "",
            });
        }
    };

    const handleSelectGuest = (newValue) => {
        if (newValue) {
            dispatchOnlineTripPlanner({
                type: "SET_GUEST_ID",
                payload: newValue.guest_id,
            });
            dispatchOnlineTripPlanner({
                type: "SET_GUEST_NAME",
                payload: newValue.guest_name,
            });
        } else {
            dispatchOnlineTripPlanner({
                type: "SET_GUEST_ID",
                payload: null,
            });
            dispatchOnlineTripPlanner({
                type: "SET_GUEST_NAME",
                payload: "",
            });
        }
    };

    // import by
    const handleImportByGuest = (newValue) => {
        if (newValue) {
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_GUEST_ID",
                payload: newValue.guest_id,
            });
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_GUEST_NAME",
                payload: newValue.guest_name,
            });
        } else {
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_GUEST_ID",
                payload: null,
            });
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_GUEST_NAME",
                payload: "",
            });
        }
    };

    const handleImportByTrip = (newValue) => {
        if (newValue) {
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_TRIP_ID",
                payload: newValue.p15_tripsid,
            });
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_TRIP_NAME",
                payload: newValue.p15_tripname,
            });
        } else {
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_TRIP_ID",
                payload: null,
            });
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_TRIP_NAME",
                payload: "",
            });
        }
    };

    const handleImportByDeparture = (newValue) => {
        if (newValue) {
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_DEPARTURE_ID",
                payload: newValue.p15_tripdeparturesid,
            });
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_DEPARTURE_NAME",
                payload: newValue.p15_name,
            });
        } else {
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_DEPARTURE_ID",
                payload: null,
            });
            dispatchOnlineTripPlanner({
                type: "SET_IMPORT_BY_DEPARTURE_NAME",
                payload: "",
            });
        }
    };

    // build
    const buildBlogsObject = () => {
        let newObj = {};

        for (let blog = 1; blog <= AUTOCOMPLETE_COMPONENT_INSTANCES; blog++) {
            newObj[`blog_${blog}`] = {};
        }

        dispatchOnlineTripPlanner({ type: "SET_BLOGS", payload: newObj });
    };

    const buildBlogsArray = (obj) => {
        let arr = [];
        let parsedObject = JSON.parse(obj);
        for (let key in parsedObject) {
            arr.push(parsedObject[key]);
        }
        return arr;
    };

    // hooks
    useEffect(() => {
        updateIsLoading(true);

        Promise.all([
            getTrips(),
            getDepartures(),
            getGuests(),
            getImportByTrips(),
            getImportByDepartures(),
            getImportByGuests(),
        ])
            .then((results) => {
                updateIsLoading(false);
                dispatchOnlineTripPlanner({
                    type: "SET_TRIPS",
                    payload: results[0],
                });
                dispatchOnlineTripPlanner({
                    type: "SET_DEPARTURES",
                    payload: results[1],
                });
                dispatchOnlineTripPlanner({
                    type: "SET_GUESTS",
                    payload: results[2],
                });

                dispatchOnlineTripPlanner({
                    type: "SET_IMPORT_BY_TRIPS",
                    payload: results[3],
                });
                dispatchOnlineTripPlanner({
                    type: "SET_IMPORT_BY_DEPARTURES",
                    payload: results[4],
                });
                dispatchOnlineTripPlanner({
                    type: "SET_IMPORT_BY_GUESTS",
                    payload: results[5],
                });
            })
            .catch((err) => {
                updateIsLoading(false);
                console.log(err);
            });
    }, []);

    return (
        <Container maxWidth="lg">
            <br></br>
            <Typography variant="h4" component="h4">
                Online Trip Planner - Management
            </Typography>
            <br></br>
            <Card className={classes.cardRoot}>
                <CardContent>
                    <br></br>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true} disabled={otp.searched}>
                            <CustomSelect
                                options={[
                                    { value: "select", name: "--Select--" },
                                    { value: "tripId", name: "Trip Name" },
                                    {
                                        value: "departureId",
                                        name: "Departure Name",
                                    },
                                    { value: "guestId", name: "Guest Name" },
                                ]}
                                inputLabelId="inputLabelSearchBy"
                                handleChange={(event) => {
                                    dispatchOnlineTripPlanner({
                                        type: "SET_SELECTED_SEARCH_BY",
                                        payload: event.target.value,
                                    });
                                }}
                                labelName="Search By"
                                value={otp.selectedSearchBy}
                                selectId="selectSearchBy"
                                selectLabelId="selectLabelSearchBy"
                            />
                        </FormControl>
                    </Grid>
                    <br></br>
                    <Grid container spacing={3}>
                        {displaySearchByTripId()}
                        {displaySearchByDepartureId()}
                        {displaySearchByGuestId()}
                    </Grid>
                    <br></br>

                    {displayImport()}
                    {displayImportByDepartureId()}
                    {displayImportByGuestId()}
                    {displayImportByTripId()}
                </CardContent>
                <CardActions>
                    <Grid container justify="space-between">
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-end"
                            item
                        >
                            {displayResetButton()}
                            {displayImportButton()}

                            <Button
                                disabled={
                                    otp.tripId || otp.departureId || otp.guestId
                                        ? false
                                        : true
                                }
                                variant="contained"
                                className={classes.link}
                                color="primary"
                                onClick={() => handleSubmit()}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
                <br></br>
            </Card>
            <br></br>
            <br></br>
            {displayResults()}

            <IframeModal
                fullScreen={true}
                heading="Preview Mode"
                isOpen={otp.toShowPreviewMode}
                handleClick={(value) =>
                    dispatchOnlineTripPlanner({
                        type: "SET_SHOW_PREVIEW_MODE",
                        payload: value,
                    })
                }
            >
                {displayOtp()}
            </IframeModal>
            <br></br>
            <br></br>
        </Container>
    );
}

export default OnlineTripPlanner;
